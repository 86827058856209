"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPropertiesHandler = void 0;
var GetPropertiesHandler = /** @class */ (function () {
    function GetPropertiesHandler(objectStore, objectMapper) {
        this.objectStore = objectStore;
        this.objectMapper = objectMapper;
    }
    /**
     * @param {boolean} ownProperties 是否只查询 ownProperties
     * @param {boolean} accessorPropertiesOnly 是否只查询访问器属性(只要有一个 getter/setter 就算)
     */
    GetPropertiesHandler.prototype.mapObject = function (obj, ownProperties, accessorPropertiesOnly) {
        var _this = this;
        if (ownProperties) {
            var propertyDescriptors_1 = Object.getOwnPropertyDescriptors(obj);
            var result = Object.keys(propertyDescriptors_1)
                .filter(function (key) {
                if (accessorPropertiesOnly
                    && !(propertyDescriptors_1[key].get || propertyDescriptors_1[key].set)) {
                    return false;
                }
                return true;
            })
                .map(function (key) {
                return _this.mapResultOne(key, propertyDescriptors_1[key], true);
            });
            if (Object.getPrototypeOf(obj)) {
                result.push(this.mapResultOne('__proto__', {
                    configurable: true,
                    enumerable: false,
                    writable: true,
                    value: Object.getPrototypeOf(obj),
                }, true));
            }
            return result;
        }
        else {
            var result_1 = [];
            var allDescriptors_1 = {};
            var owner = obj;
            var protoPushed = false;
            var isOwn_1 = true;
            var _loop_1 = function () {
                var propertyDescriptors = Object.getOwnPropertyDescriptors(owner);
                // eslint-disable-next-line no-loop-func
                Object.keys(propertyDescriptors).forEach(function (key) {
                    if (!allDescriptors_1[key]) {
                        allDescriptors_1[key] = true;
                        var descriptor = propertyDescriptors[key];
                        if (!accessorPropertiesOnly || descriptor.get || descriptor.set) {
                            result_1.push(_this.mapResultOne(key, descriptor, isOwn_1));
                        }
                    }
                });
                if (!protoPushed) {
                    var protoDescriptor = Object.getOwnPropertyDescriptor(owner, '__proto__');
                    if (protoDescriptor) {
                        protoPushed = true;
                        result_1.push(this_1.mapResultOne('__proto__', protoDescriptor, isOwn_1));
                    }
                }
                owner = Object.getPrototypeOf(owner);
                isOwn_1 = false;
            };
            var this_1 = this;
            while (owner) {
                _loop_1();
            }
            return result_1;
        }
    };
    GetPropertiesHandler.prototype.mapResultOne = function (key, descriptor, isOwn) {
        var result = {
            writable: descriptor.writable,
            configurable: Boolean(descriptor.configurable),
            enumerable: Boolean(descriptor.enumerable),
            isOwn: isOwn,
            name: String(key),
        };
        // value 可以为 undefined
        if ('value' in descriptor) {
            result.value = this.objectMapper.mapFromOne(descriptor.value);
        }
        if (descriptor.get) {
            result.get = this.objectMapper.mapFromOne(descriptor.get);
        }
        if (descriptor.set) {
            result.set = this.objectMapper.mapFromOne(descriptor.set);
        }
        return result;
    };
    return GetPropertiesHandler;
}());
exports.GetPropertiesHandler = GetPropertiesHandler;
