"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config_1 = require("../common/config");
var tips_1 = require("../common/tips");
var types_1 = require("../common/types");
var element_1 = require("../plugin/element");
var log_1 = require("../plugin/log");
var network_1 = require("../plugin/network");
var getReasonByEventCode_1 = require("../utils/getReasonByEventCode");
var getWebsocketUrl_1 = require("../utils/getWebsocketUrl");
var logger_1 = require("../utils/logger");
var framework_monitor_1 = require("../utils/monitor/framework-monitor");
var cdp_1 = require("./cdp");
var cdp_method_1 = require("./cdp-method");
var context_1 = require("./context");
var monitor = framework_monitor_1.default(config_1.default.yuyanId);
var Core = /** @class */ (function () {
    function Core(opts) {
        this.opts = opts;
        this.initial();
    }
    Core.prototype.emitPartialEnable = function () {
        // 目前devtools不会发送部分enable事件，需要我们主动发起
        // 在页面reload，devtools不reload的情况下，devtools不会重新发起enable事件
        cdp_method_1.default.Log.enable({
            id: '',
            method: "Log.enable" /* enable */,
            params: undefined,
        }, this.socket);
        cdp_method_1.default.MiniAppLog.enable({
            id: '',
            method: "MiniAppLog.enable" /* enable */,
            params: undefined,
        }, this.socket);
        cdp_method_1.default.Network.enable({
            id: '',
            method: "Network.enable" /* enable */,
            params: undefined,
        }, this.socket);
        cdp_method_1.default.Runtime.enable({
            id: '',
            method: "Runtime.enable" /* enable */,
            params: undefined,
        }, this.socket, this.handlerContext);
    };
    Core.prototype.emitDocumentUpdated = function () {
        this.socket.fireEvent({
            method: "DOM.documentUpdated" /* documentUpdated */,
            // @ts-ignore
            params: {
                bugmeAgentId: getWebsocketUrl_1.getAgentId(),
            },
        });
    };
    Core.prototype.setup = function () {
        /** 主动触发部分devtools不发送enable事件 */
        this.emitPartialEnable();
        /** 刷新nodeId */
        this.emitDocumentUpdated();
        /** 注册插件 */
        element_1.default(this.socket, this.handlerContext);
        log_1.default(this.socket, this.handlerContext);
        network_1.default(this.socket);
    };
    Core.prototype.handleServerProtocol = function (msg) {
        if (msg === types_1.EServerProtocol.HOST_LEAVE) {
            this.handlerContext.modalComponent.$$set({
                show: true,
                content: tips_1.TIPS_DEVTOOLS_DISCONNECT,
            });
        }
        else if (msg === types_1.EServerProtocol.CLIENT_LEAVE) {
            this.handlerContext.modalComponent.$$set({
                show: true,
                content: tips_1.TIPS_DEVTOOLS_DISCONNECT,
            });
        }
    };
    Core.prototype.handleOnClose = function (ev) {
        logger_1.debug('socket close', ev);
        if (ev.code === 4003) {
            this.handlerContext.modalComponent.$$set({
                show: true,
                content: tips_1.TIPS_DEVTOOLS_HASCONNECT,
            });
        }
        else if (ev.code === 1005) {
            this.handlerContext.modalComponent.$$set({
                show: true,
                content: tips_1.TIPS_DEVTOOLS_DISCONNECT,
            });
        }
        else {
            this.handlerContext.modalComponent.$$set({
                show: true,
                content: getReasonByEventCode_1.default(ev),
            });
        }
    };
    Core.prototype.initial = function () {
        /** 初始化处理器上下文 */
        this.handlerContext = new context_1.default({
            observerElement: this.opts.observerElement,
        });
        /** 初始化websocket链接 */
        this.socket = new cdp_1.default({
            uuid: this.opts.uuid,
            onMessage: this.process.bind(this),
            onOpen: this.setup.bind(this),
            onClose: this.handleOnClose.bind(this),
        });
    };
    Core.prototype.process = function (ev) {
        var msg = ev.data;
        var parsedMsg;
        try {
            parsedMsg = JSON.parse(msg);
        }
        catch (e) {
            logger_1.debug('<---------- receive data', msg);
            this.handleServerProtocol(msg);
            return;
        }
        logger_1.debug('<---------- receive data', parsedMsg);
        var methodWithDomain = parsedMsg.method;
        if (!methodWithDomain) {
            logger_1.error('unknown domain and method', parsedMsg);
            return;
        }
        var domain = methodWithDomain.split('.')[0];
        var method = methodWithDomain.split('.')[1];
        if (!domain || !method) {
            logger_1.error('unknown domain or method', parsedMsg);
            monitor.logJSError(new Error("unknown domain or method. " + msg));
            return;
        }
        if (methodWithDomain === 'DOM.undo' || methodWithDomain === 'DOM.redo') {
            this.handlerContext.tipsComponent.info(tips_1.TIPS_NOT_SUPPORT_UNDO_REDO);
        }
        if (!cdp_method_1.default[domain] || !cdp_method_1.default[domain][method]) {
            logger_1.debug("Cannot find handler for domain " + domain + " or method " + method, parsedMsg);
            return;
        }
        var handler = cdp_method_1.default[domain][method];
        handler(parsedMsg, this.socket, this.handlerContext);
    };
    return Core;
}());
exports.default = Core;
