"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.info = exports.warn = exports.error = exports.debug = exports.setLoggerLevel = exports.ELoggerLevel = void 0;
var config_1 = require("../common/config");
var url_1 = require("./url");
/* eslint-disable no-console */
var ELoggerLevel;
(function (ELoggerLevel) {
    ELoggerLevel[ELoggerLevel["error"] = 1] = "error";
    ELoggerLevel[ELoggerLevel["warn"] = 2] = "warn";
    ELoggerLevel[ELoggerLevel["info"] = 3] = "info";
    ELoggerLevel[ELoggerLevel["debug"] = 4] = "debug";
})(ELoggerLevel = exports.ELoggerLevel || (exports.ELoggerLevel = {}));
var isDebug = url_1.getSearchParam(config_1.default.debugSymbol);
var LOGGER_LEVEL = isDebug ? ELoggerLevel.debug : ELoggerLevel.info;
var LOGGER_PREFIX = 'h5_remote_debug_sdk';
var setLoggerLevel = function (loggerLevel) {
    LOGGER_LEVEL = loggerLevel;
};
exports.setLoggerLevel = setLoggerLevel;
var debug = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (LOGGER_LEVEL >= ELoggerLevel.debug) {
        console.info.apply(console, __spreadArray(["[" + LOGGER_PREFIX + "]"], args));
    }
};
exports.debug = debug;
var error = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (LOGGER_LEVEL >= ELoggerLevel.error) {
        console.error.apply(console, __spreadArray(["[" + LOGGER_PREFIX + "]"], args));
    }
};
exports.error = error;
var warn = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (LOGGER_LEVEL >= ELoggerLevel.warn) {
        console.warn.apply(console, __spreadArray(["[" + LOGGER_PREFIX + "]"], args));
    }
};
exports.warn = warn;
var info = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (LOGGER_LEVEL >= ELoggerLevel.info) {
        console.log.apply(console, __spreadArray(["[" + LOGGER_PREFIX + "]"], args));
    }
};
exports.info = info;
exports.default = {
    info: exports.info,
    debug: exports.debug,
    warn: exports.warn,
    error: exports.error,
    setLoggerLevel: exports.setLoggerLevel,
};
