"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg, socket) {
    var params = msg.params;
    var name = params.name, nodeId = params.nodeId, value = params.value;
    var node = elementStore_1.default.get(nodeId);
    if (node) {
        if (name === 'class') {
            var element = node.element;
            element.setAttribute('class', value);
            socket.response({
                id: msg.id,
                result: {},
            });
        }
    }
});
