"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (msg, socket, handlerContext) {
    var observerElement = handlerContext.observerElement;
    if (msg.params.mode === 'none') {
        leaveInspectMode();
        return;
    }
    var hidehighlight = handlerContext.hidehighlight.bind(handlerContext);
    observerElement.addEventListener('touchstart', highlightNode, false);
    observerElement.addEventListener('touchend', hidehighlightNode, false);
    observerElement.addEventListener('mouseover', highlightNode, false);
    observerElement.addEventListener('mouseout', hidehighlightNode, false);
    function highlightNode(ev) {
        var domNode = ev.target;
        handlerContext.highlightNode(domNode, msg.params.highlightConfig);
        if (domNode.nodeType === Node.ELEMENT_NODE) {
            var vnode = domNode['__vnode__'];
            if (vnode && vnode.nodeId) {
                socket.fireEvent({
                    method: "Overlay.nodeHighlightRequested" /* nodeHighlightRequested */,
                    params: {
                        nodeId: vnode.nodeId,
                    },
                });
            }
        }
    }
    function hidehighlightNode(ev) {
        ev.stopPropagation();
        hidehighlight();
        leaveInspectMode();
        // inspectNodeRequested 通知确认选中某个结点，并退出 inspect mode
        var domNode = ev.target;
        if (domNode.nodeType === Node.ELEMENT_NODE) {
            var vnode = domNode['__vnode__'];
            if (vnode && vnode.nodeId) {
                socket.fireEvent({
                    method: "Overlay.inspectNodeRequested" /* inspectNodeRequested */,
                    params: {
                        backendNodeId: vnode.nodeId,
                    },
                });
            }
        }
    }
    function leaveInspectMode() {
        observerElement.removeEventListener('touchstart', highlightNode, false);
        observerElement.removeEventListener('touchend', hidehighlightNode, false);
        observerElement.removeEventListener('mouseover', highlightNode, false);
        observerElement.removeEventListener('mouseout', hidehighlightNode, false);
    }
});
