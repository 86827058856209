"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var css_1 = require("../../../utils/css");
exports.default = (function (msg, socket) {
    var id = msg.id, params = msg.params;
    socket.response({
        id: id,
        result: {
            styles: css_1.setStyleTexts(params),
        },
    });
});
