"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
function extractClassName(chilrens, classNames) {
    for (var i = 0; i < chilrens.length; i++) {
        var vnode = chilrens[i];
        var nodeId = vnode.nodeId;
        var node = elementStore_1.default.get(nodeId);
        var element = node.element;
        if (element && element.className) {
            classNames.push(element.className);
        }
        if (vnode.children)
            extractClassName(vnode.children, classNames);
    }
}
exports.default = (function (msg, socket) {
    var node = elementStore_1.default.get(msg.params.nodeId);
    var vnode = node.node;
    var classNames = [];
    if (node.element && node.element.className) {
        classNames.push(node.element.className);
    }
    if (vnode === null || vnode === void 0 ? void 0 : vnode.children) {
        extractClassName(vnode.children, classNames);
    }
    socket.response({
        id: msg.id,
        result: {
            classNames: classNames,
        },
    });
});
