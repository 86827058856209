"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var objectManager_1 = require("../../../utils/objectManager");
var objectStore_1 = require("../../../utils/objectStore");
var runtime_1 = require("../../../utils/runtime");
exports.default = (function (msg, socket, handlerContext) {
    var id = msg.id, params = msg.params;
    var _a = params.objectId, objectId = _a === void 0 ? '' : _a, functionDeclaration = params.functionDeclaration, args = params.arguments, returnByValue = params.returnByValue;
    var scope = objectStore_1.default.query(objectId);
    var processedArgs = args ? args.map(function (v) {
        return v.unserializableValue ? v.value : JSON.stringify(v.value);
    }) : [];
    var functionStr = "(" + functionDeclaration + ").call(this, " + processedArgs.join(',') + ")";
    var _b = runtime_1.callFn(functionStr, scope), error = _b.error, scriptId = _b.scriptId, hash = _b.hash, value = _b.value;
    // @ts-ignore
    if (error && error.wasThrown) {
        var exceptionDetails = {
            columnNumber: 0,
            exception: handlerContext.objectMapper.mapFromOne(error, {
                returnByValue: returnByValue,
            }),
            exceptionId: scriptId,
            lineNumber: 0,
            scriptId: scriptId.toString(),
            text: 'Uncaught',
        };
        return socket.response({
            id: id,
            result: {
                exceptionDetails: exceptionDetails,
                result: {
                    type: 'undefined',
                },
            },
        });
    }
    socket.fireEvent({
        method: "Debugger.scriptParsed" /* scriptParsed */,
        params: {
            hash: hash,
            startColumn: 0,
            endColumn: 0,
            startLine: 0,
            endLine: 0,
            executionContextId: objectManager_1.executionContextId,
            executionContextAuxData: {
                frameId: '1.0',
                isDefault: true,
            },
            scriptId: "" + scriptId,
            hasSourceURL: false,
            isLiveEdit: false,
            sourceMapURL: '',
            url: '',
        },
    });
    return socket.response({
        id: id,
        result: {
            result: handlerContext.objectMapper.mapFromOne(value, {
                returnByValue: returnByValue,
            }),
        },
    });
});
