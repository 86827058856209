"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var objectManager_1 = require("../../../utils/objectManager");
var evalFunc = typeof eval === 'function' ? eval : function () { };
exports.default = (function (msg, socket) {
    var id = msg.id;
    var returnValue = evalFunc('this');
    var propertyDescriptors = objectManager_1.getObjectOwnPropertyDescriptors(returnValue);
    var propertyKeys = Object.keys(propertyDescriptors);
    socket.response({
        id: id,
        result: {
            names: propertyKeys,
        },
    });
});
