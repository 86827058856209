"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getElementInterfaceName_1 = require("../../../utils/getElementInterfaceName");
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg, socket) {
    var params = msg.params;
    if (params === null || params === void 0 ? void 0 : params.nodeId) {
        var node = elementStore_1.default.get(params.nodeId);
        var interfaceName = getElementInterfaceName_1.default(node.element);
        var localName = node.element.localName;
        socket.response({
            id: msg.id,
            result: {
                object: {
                    className: interfaceName,
                    description: localName,
                    type: 'object',
                    subtype: 'node',
                },
            },
        });
    }
});
