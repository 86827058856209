"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallFramesMapper = exports.ExtractStackLine = void 0;
var ExtractStackLine = /** @class */ (function () {
    function ExtractStackLine() {
        this.e1 = /\s*at\s(\S+)\s(\S+):(\d+):(\d+)\)/;
        this.e2 = /\s*at\s(\S+):(\d+):(\d+)/;
    }
    ExtractStackLine.prototype.testType1 = function (t1) {
        return this.e1.test(t1);
    };
    ExtractStackLine.prototype.testType2 = function (t2) {
        return this.e2.test(t2);
    };
    ExtractStackLine.prototype.extractType1 = function (t1) {
        var group = t1.match(this.e1);
        if (group == null)
            return null;
        var functionName = group[1];
        var url = group[2].slice(1);
        var lineNumber = Number(group[3]);
        var columnNumber = Number(group[4]);
        return {
            functionName: functionName,
            url: url,
            lineNumber: lineNumber,
            columnNumber: columnNumber,
        };
    };
    ExtractStackLine.prototype.extractType2 = function (t2) {
        var group = t2.match(this.e2);
        if (group == null)
            return null;
        var url = group[1].slice(1);
        var lineNumber = Number(group[2]);
        var columnNumber = Number(group[3]);
        return {
            functionName: '',
            url: url,
            lineNumber: lineNumber,
            columnNumber: columnNumber,
        };
    };
    return ExtractStackLine;
}());
exports.ExtractStackLine = ExtractStackLine;
var CallFramesMapper = /** @class */ (function (_super) {
    __extends(CallFramesMapper, _super);
    function CallFramesMapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CallFramesMapper.prototype.mapFrom = function (stack, ignoreSlice) {
        var _this = this;
        var startLine = 2;
        var lines = stack.split('\n').slice(startLine);
        if (ignoreSlice) {
            lines = stack.split('\n');
        }
        var trans = lines
            .map(function (l) {
            if (_this.testType1(l)) {
                return _this.extractType1(l);
            }
            if (_this.testType2(l)) {
                return _this.extractType2(l);
            }
        })
            .filter(function (n) { return n != null; });
        return trans;
    };
    return CallFramesMapper;
}(ExtractStackLine));
exports.CallFramesMapper = CallFramesMapper;
