"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkError = exports.isInternalRequest = exports.isErrorStatus = void 0;
var config_1 = require("../../common/config");
var framework_monitor_1 = require("../monitor/framework-monitor");
var monitor = framework_monitor_1.default(config_1.default.yuyanId);
var internalRequestHostCollection = [
    '1514539673795777.cn-hangzhou.fc.aliyuncs.com',
    'mdap.alipay.com',
];
function isErrorStatus(status) {
    var status2str = "" + status;
    // 4xx / 5xx错误
    if (status2str[0] === '4' || status2str[0] === '5') {
        return true;
    }
    else {
        return false;
    }
}
exports.isErrorStatus = isErrorStatus;
function isInternalRequest(url) {
    try {
        var urlInst = new URL(url, location.origin);
        return internalRequestHostCollection.indexOf(urlInst.host) !== -1;
    }
    catch (e) {
        monitor.logJSError(e);
        return false;
    }
}
exports.isInternalRequest = isInternalRequest;
var NetworkError = /** @class */ (function (_super) {
    __extends(NetworkError, _super);
    function NetworkError(msg) {
        var _this = _super.call(this, msg) || this;
        _this.name = 'NetworkError';
        return _this;
    }
    return NetworkError;
}(Error));
exports.NetworkError = NetworkError;
