"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var enable_1 = require("./enable");
var hideHighlight_1 = require("./hideHighlight");
var highlightNode_1 = require("./highlightNode");
var setInspectMode_1 = require("./setInspectMode");
exports.default = {
    enable: enable_1.default,
    highlightNode: highlightNode_1.default,
    hideHighlight: hideHighlight_1.default,
    setInspectMode: setInspectMode_1.default,
};
