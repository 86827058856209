"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable max-len */
var config_1 = require("../common/config");
var getReasonByEventCode_1 = require("./getReasonByEventCode");
var framework_monitor_1 = require("./monitor/framework-monitor");
var monitor = framework_monitor_1.default(config_1.default.yuyanId);
var WebsocketFactory = /** @class */ (function () {
    function WebsocketFactory(opts) {
        var _this = this;
        var url = opts.url, onClose = opts.onClose, onError = opts.onError, onMessage = opts.onMessage, onOpen = opts.onOpen;
        this.hasOpen = false;
        this.dataQueue = [];
        this.socket = new WebSocket(url);
        this.socket.onclose = function (ev) {
            try {
                onClose === null || onClose === void 0 ? void 0 : onClose.call(null, ev);
            }
            catch (e) {
                monitor.logJSError(e);
            }
            finally {
                _this.reportOnClose(url, ev);
            }
        };
        this.socket.onerror = onError;
        this.socket.onmessage = onMessage;
        this.socket.onopen = function (ev) {
            _this.hasOpen = true;
            _this.flushData();
            onOpen === null || onOpen === void 0 ? void 0 : onOpen.call(null, ev);
        };
    }
    WebsocketFactory.prototype.close = function () {
        this.socket.close();
    };
    WebsocketFactory.prototype.send = function (data) {
        this.dataQueue.push(data);
        if (this.hasOpen) {
            this.flushData();
        }
    };
    WebsocketFactory.prototype.reportOnClose = function (url, event) {
        var reason = getReasonByEventCode_1.default(event);
        monitor.logJSError(new Error("Websocket(" + url + ") Close. Code: " + event.code + ". Message: " + reason));
    };
    WebsocketFactory.prototype.flushData = function () {
        var _this = this;
        var _loop_1 = function () {
            var dataSlice = this_1.dataQueue.shift();
            dataSlice && setTimeout(function () {
                _this.socket.send(dataSlice);
            });
        };
        var this_1 = this;
        while (this.dataQueue.length > 0) {
            _loop_1();
        }
    };
    return WebsocketFactory;
}());
exports.default = WebsocketFactory;
