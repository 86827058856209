"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectMapper = exports.isPromise = exports.isRegExp = exports.isDate = exports.isError = exports.getObjectOwnPropertyDescriptors = exports.executionContextId = void 0;
var getConstructorName_1 = require("./getConstructorName");
exports.executionContextId = 1;
function getObjectOwnPropertyDescriptors(obj) {
    // iOS10 开始支持 Object.getOwnPropertyDescriptors
    // Object.getOwnPropertyDescriptor iOS8/9 都支持
    if (Object.getOwnPropertyDescriptors) {
        return Object.getOwnPropertyDescriptors(obj);
    }
    else {
        var ownPropertyKeys = Object.getOwnPropertyNames(obj);
        return ownPropertyKeys.reduce(function (prev, cur) {
            prev[cur] = Object.getOwnPropertyDescriptor(obj, cur);
            return prev;
        }, Object.create(null));
    }
}
exports.getObjectOwnPropertyDescriptors = getObjectOwnPropertyDescriptors;
function isError(obj) {
    return Object.prototype.toString.call(obj) === '[object Error]' || obj instanceof Error;
}
exports.isError = isError;
function isDate(obj) {
    return Object.prototype.toString.call(obj) === '[object Date]' || obj instanceof Date;
}
exports.isDate = isDate;
function isRegExp(obj) {
    return Object.prototype.toString.call(obj) === '[object RegExp]' || obj instanceof RegExp;
}
exports.isRegExp = isRegExp;
function isPromise(obj) {
    // iOS 全局 Promise 来自 Polyfill，toString 始终为 [object Object]
    return Object.prototype.toString.call(obj) === '[object Promise]' || obj instanceof Promise;
}
exports.isPromise = isPromise;
var ObjectMapper = /** @class */ (function () {
    function ObjectMapper(objectStore) {
        this.objectStore = objectStore;
    }
    ObjectMapper.prototype.mapFrom = function (args, opts) {
        var _this = this;
        return Array.from(args).map(function (arg) {
            return _this.mapFromOne(arg, opts || {
                returnByValue: false,
            });
        });
    };
    ObjectMapper.prototype.mapFromOne = function (arg, opts) {
        var type = typeof arg;
        var _a = opts || {}, _b = _a.returnByValue, returnByValue = _b === void 0 ? false : _b, _c = _a.consoleMethod, consoleMethod = _c === void 0 ? '' : _c;
        var subtype = getConstructorName_1.default(arg).toLowerCase();
        if (type.match(/^(number|string|undefined|boolean)$/) || subtype === 'null' || (subtype === 'array' && returnByValue)) {
            return {
                type: type,
                value: arg,
            };
        }
        switch (typeof arg) {
            case 'string':
                return this.mapString(arg);
            case 'number':
                return this.mapNumber(arg);
            case 'object':
                if (Array.isArray(arg)) {
                    return this.mapArray(arg, consoleMethod === 'table');
                }
                else if (arg === null) {
                    return this.mapNull();
                }
                else {
                    return this.mapObject(arg, consoleMethod === 'table');
                }
            case 'function':
                return this.mapFunction(arg);
            case 'undefined':
                return this.mapUndef();
            case 'boolean':
                return this.mapBoolean(arg);
            default:
                return {
                    type: type,
                    value: arg,
                };
        }
    };
    ObjectMapper.prototype.mapString = function (arg) {
        return {
            type: 'string',
            value: arg,
        };
    };
    ObjectMapper.prototype.mapNumber = function (arg) {
        return {
            type: 'number',
            value: arg,
            description: String(arg),
        };
    };
    ObjectMapper.prototype.mapBoolean = function (value) {
        return {
            type: 'boolean',
            value: value,
        };
    };
    ObjectMapper.prototype.mapFunction = function (arg) {
        var objectId = this.objectStore.save(arg);
        return {
            type: 'function',
            className: 'Function',
            description: arg.toString(),
            objectId: objectId,
        };
    };
    ObjectMapper.prototype.mapUndef = function () {
        return {
            type: 'undefined',
        };
    };
    ObjectMapper.prototype.mapNull = function () {
        return {
            type: 'object',
            subtype: 'null',
            value: null,
        };
    };
    ObjectMapper.prototype.mapObject = function (arg, isTable) {
        var _this = this;
        var propertyDescriptors = getObjectOwnPropertyDescriptors(arg);
        var ownPropertyKeys = Object.keys(propertyDescriptors);
        var objectId = this.objectStore.save(arg);
        var constructorName = getConstructorName_1.default(arg);
        var shouldOverflow = ownPropertyKeys.length > 5 && !isTable;
        var result = {
            type: 'object',
            className: constructorName,
            description: constructorName,
            objectId: objectId,
            preview: {
                type: 'object',
                description: constructorName,
                overflow: shouldOverflow,
                properties: (shouldOverflow ? ownPropertyKeys.slice(0, 5) : ownPropertyKeys)
                    .map(function (key) { return _this.getPropertyPreview4ObjectProperty(key, propertyDescriptors[key], isTable); })
                    .filter(Boolean),
            },
        };
        var subtype;
        var description;
        if (isError(arg)) {
            subtype = 'error';
            description = arg.stack || '';
            // 兼容 iOS error.stack 不包含 error.message
            if (description && description.indexOf(arg.name) === -1) {
                description = arg.toString() + "\n" + description;
            }
        }
        else if (isDate(arg)) {
            subtype = 'date';
            description = arg.toString();
        }
        else if (isRegExp(arg)) {
            subtype = 'regexp';
            description = arg.toString();
        }
        else if (isPromise(arg)) {
            subtype = 'promise';
            description = 'Promise';
        }
        if (subtype) {
            result.subtype = subtype;
            if (result.preview) {
                result.preview.subtype = subtype;
            }
        }
        if (description) {
            result.description = description;
            if (result.preview) {
                result.preview.description = description;
            }
        }
        return result;
    };
    ObjectMapper.prototype.mapArray = function (arg, isTable) {
        var _this = this;
        var shouldOverflow = arg.length > 100 && !isTable;
        var desc = "Array(" + arg.length + ")";
        var objectId = this.objectStore.save(arg);
        return {
            type: 'object',
            subtype: 'array',
            className: 'Array',
            description: desc,
            objectId: objectId,
            preview: {
                type: 'object',
                subtype: 'array',
                description: desc,
                overflow: shouldOverflow,
                properties: (shouldOverflow ? arg.slice(0, 100) : arg).map(function (item, index) {
                    return _this.getPropertyPreview4ArrayProperty(index, item, isTable);
                }),
            },
        };
    };
    ObjectMapper.prototype.getPropertyPreview4ArrayProperty = function (index, item, isTable) {
        return this.getPropertyPreview4AnyValue(String(index), item, isTable);
    };
    ObjectMapper.prototype.getPropertyPreview4ObjectProperty = function (key, descriptor, isTable) {
        if (descriptor.get) {
            return {
                name: key,
                type: 'accessor',
            };
        }
        // 没有 getter 只有 setter 则忽略这个属性
        if (descriptor.set) {
            return;
        }
        return this.getPropertyPreview4AnyValue(key, descriptor.value, isTable);
    };
    ObjectMapper.prototype.getPropertyPreview4AnyValue = function (key, value, showValuePreview) {
        if (showValuePreview === void 0) { showValuePreview = false; }
        switch (typeof value) {
            case 'object':
                if (Array.isArray(value)) {
                    var r = {
                        name: key,
                        type: 'object',
                        subtype: 'array',
                        value: "Array(" + value.length + ")",
                    };
                    if (showValuePreview) {
                        r.valuePreview = this.getValuePreview4TableArray(value);
                    }
                    return r;
                }
                else if (value === null) {
                    return {
                        name: key,
                        type: 'object',
                        subtype: 'null',
                        value: 'null',
                    };
                }
                else {
                    try {
                        var r = {
                            name: key,
                            type: 'object',
                            value: value.constructor ? value.constructor.name : 'Object',
                        };
                        if (showValuePreview) {
                            r.valuePreview = this.getValuePreview4TableObject(value);
                        }
                        return r;
                    }
                    catch (e) {
                        var r = {
                            name: key,
                            type: 'object',
                            value: 'Window',
                        };
                        if (showValuePreview) {
                            r.valuePreview = this.getValuePreview4TableObject(value);
                        }
                        return r;
                    }
                }
            case 'function':
                return {
                    type: 'function',
                    value: '',
                    name: key,
                };
            default:
                return {
                    name: key,
                    type: typeof value,
                    value: String(value),
                };
        }
    };
    /**
     * console.table 支持一维或二维逻辑关系
     * 如果属性为 Array/Object，需要展开 Properties 的 Preview
     *
     * RemoteObject
     *  |-- preview: ObjectPreview
     *       |-- properties: PropertyPreview
     *            |-- valuePreview: ObjectPreview
     */
    ObjectMapper.prototype.getValuePreview4TableArray = function (arr) {
        var _this = this;
        return {
            type: 'object',
            subtype: 'array',
            description: "Array(" + arr.length + ")",
            overflow: false,
            properties: arr.map(function (value, index) {
                return _this.getPropertyPreview4AnyValue(String(index), value);
            }),
        };
    };
    ObjectMapper.prototype.getValuePreview4TableObject = function (obj) {
        var _this = this;
        return {
            type: 'object',
            description: 'Object',
            overflow: false,
            properties: Object.keys(obj).map(function (k) {
                return _this.getPropertyPreview4AnyValue(k, obj[k]);
            }),
        };
    };
    return ObjectMapper;
}());
exports.ObjectMapper = ObjectMapper;
