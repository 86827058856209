"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (msg, socket, handlerContext) {
    /**
     * 场景：
     * 1. 展开普通对象: 先 { ownProperties: false, accessorPropertiesOnly: true } 后 { ownProperties: true, accessorPropertiesOnly: false }
     * 2. 展开 Scope.Local: 先 { ownProperties: false, accessorPropertiesOnly: false } 后 { ownProperties: false, accessorPropertiesOnly: false }
     */
    var obj = handlerContext.objectStore.query(msg.params.objectId);
    var result = [];
    if (obj) {
        result = handlerContext.getPropertiesHandler.mapObject(obj, Boolean(msg.params.ownProperties), Boolean(msg.params.accessorPropertiesOnly));
    }
    socket.response({
        id: msg.id,
        result: {
            result: result,
        },
    });
});
