"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNodeTextContentByPrototype = exports.refreshParsedStyleSheetById = exports.setStyleTexts4StyleSheet = exports.setStyleTexts = exports.getInlineStylesForNode = exports.postCSSChildNode2CSSPropertyProtocol = exports.postCSSRule2CSSStyleProtocol = exports.postCSSRule2CSSRuleMatchProtocol = exports.getSpecificitySortedRuleMatchList = exports.getMatchedStylesForNode = exports.getInheritedStyle = exports.getInlineStyle4Fiber = exports.cssText2CSSStyleProtocol = exports.elementMatches = exports.traversePageCSSRules = exports.parsedStyleSheet2StyleSheetHeaderProtocol = exports.getParsedStyleSheetById = exports.getParsedStyleSheets = void 0;
var lodash_1 = require("lodash");
var postcss_1 = require("postcss");
var selectorParser = require("postcss-selector-parser");
var supertagent = require("superagent");
var commonCss_txt_1 = require("../../assets/commonCss.txt");
var config_1 = require("../../common/config");
var elementStore_1 = require("../../lib/elementStore");
var logger_1 = require("../logger");
var framework_monitor_1 = require("../monitor/framework-monitor");
var monitor = framework_monitor_1.default(config_1.default.yuyanId);
/**
 * 计算 css 选择器权重
 * @param selector 单个选择器字符串，如果包含多个选择器只会计算第一个
 */
var selectorSepcificityCache = {};
var parsedStyleSheets = new Map();
var ESelectorSpecificityIncrement;
(function (ESelectorSpecificityIncrement) {
    ESelectorSpecificityIncrement[ESelectorSpecificityIncrement["ClassA"] = 65536] = "ClassA";
    ESelectorSpecificityIncrement[ESelectorSpecificityIncrement["ClassB"] = 256] = "ClassB";
    ESelectorSpecificityIncrement[ESelectorSpecificityIncrement["ClassC"] = 1] = "ClassC";
})(ESelectorSpecificityIncrement || (ESelectorSpecificityIncrement = {}));
// const pseudoBeforeRe = /::?before\b/;
// const pseudoAfterRe = /::?after\b/;
/**
 * 初始化解析 <style>
 */
function getParsedStyleSheets() {
    return __awaiter(this, void 0, void 0, function () {
        var resetStyleSheetId, _i, _a, styleSheetItem, cssText, styleSheetRoot, styleSheetId, styleSheet, styleNode, headElement, styleSheetId, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!!parsedStyleSheets.size) return [3 /*break*/, 7];
                    resetStyleSheetId = "1000." + parsedStyleSheets.size;
                    parsedStyleSheets.set(resetStyleSheetId, {
                        ownerNode: {
                            textContent: commonCss_txt_1.default.length,
                        },
                        postCSSRoot: postcss_1.default.parse(commonCss_txt_1.default || ''),
                        styleSheetId: resetStyleSheetId,
                        sourceUrl: '',
                        origin: 'user-agent',
                        cssText: commonCss_txt_1.default,
                    });
                    _i = 0, _a = Array.from(document.styleSheets);
                    _b.label = 1;
                case 1:
                    if (!(_i < _a.length)) return [3 /*break*/, 7];
                    styleSheetItem = _a[_i];
                    if (!(!styleSheetItem.href && styleSheetItem.ownerNode)) return [3 /*break*/, 2];
                    try {
                        cssText = styleSheetItem.ownerNode.textContent || '';
                        styleSheetRoot = postcss_1.default.parse(cssText);
                        styleSheetId = "1000." + parsedStyleSheets.size;
                        parsedStyleSheets.set(styleSheetId, {
                            ownerNode: styleSheetItem.ownerNode,
                            postCSSRoot: styleSheetRoot,
                            styleSheetId: styleSheetId,
                            sourceUrl: '',
                            origin: 'regular',
                            cssText: cssText,
                        });
                    }
                    catch (err) {
                        logger_1.warn(err);
                    }
                    return [3 /*break*/, 6];
                case 2:
                    if (!styleSheetItem.href) return [3 /*break*/, 6];
                    _b.label = 3;
                case 3:
                    _b.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, supertagent
                            .get("https://1514539673795777.cn-hangzhou.fc.aliyuncs.com/2016-08-15/proxy/remoteDebugSdkMiddleware/getExternalStyleSheet/?linkUrl=" + encodeURIComponent(styleSheetItem.href))
                            .timeout(3000)
                            .retry(3)
                            .type('')
                            .then(function (res) {
                            var content = JSON.parse(res.text);
                            return content.styleSheet;
                        })];
                case 4:
                    styleSheet = _b.sent();
                    styleNode = document.createElement('style');
                    headElement = document.head || document.getElementsByTagName('head')[0];
                    headElement.appendChild(styleNode);
                    styleNode.type = 'text/css';
                    if (styleNode['styleSheet']) {
                        // This is required for IE8 and below.
                        styleNode['styleSheet'].cssText = styleSheet;
                    }
                    else {
                        styleNode.appendChild(document.createTextNode(styleSheet));
                    }
                    // 将remote cssText置为disabled，避免与inlineStyle冲突
                    styleSheetItem.disabled = true;
                    styleSheetId = "1000." + parsedStyleSheets.size;
                    parsedStyleSheets.set(styleSheetId, {
                        ownerNode: styleNode,
                        postCSSRoot: postcss_1.default.parse(styleSheet || ''),
                        styleSheetId: styleSheetId,
                        sourceUrl: styleSheetItem.href,
                        origin: 'regular',
                        cssText: styleSheet,
                    });
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _b.sent();
                    logger_1.error(e_1);
                    monitor.logJSError(e_1);
                    return [3 /*break*/, 6];
                case 6:
                    _i++;
                    return [3 /*break*/, 1];
                case 7: return [2 /*return*/, parsedStyleSheets];
            }
        });
    });
}
exports.getParsedStyleSheets = getParsedStyleSheets;
function getParsedStyleSheetById(styleSheetId) {
    return parsedStyleSheets.get(styleSheetId);
}
exports.getParsedStyleSheetById = getParsedStyleSheetById;
/**
 * 构造 CSS.CSSStyleSheetHeader
 */
function parsedStyleSheet2StyleSheetHeaderProtocol(sheet) {
    var postCSSRoot = sheet.postCSSRoot, ownerNode = sheet.ownerNode, styleSheetId = sheet.styleSheetId, origin = sheet.origin, sourceUrl = sheet.sourceUrl;
    return {
        disabled: false,
        endColumn: postCSSRoot.last ? postCSSRoot.last.source.end.column - 1 : 0,
        endLine: postCSSRoot.last ? postCSSRoot.last.source.end.line - 1 : 0,
        frameId: 'MOCK_FRAME_ID',
        isInline: !sourceUrl,
        length: lodash_1.get(ownerNode, 'textContent.length', 0),
        origin: origin,
        sourceURL: sourceUrl,
        startColumn: postCSSRoot.first ? postCSSRoot.first.source.start.column - 1 : 0,
        startLine: postCSSRoot.first ? postCSSRoot.first.source.start.line - 1 : 0,
        styleSheetId: styleSheetId,
        title: '',
        isMutable: origin === 'regular',
        isConstructed: false,
    };
}
exports.parsedStyleSheet2StyleSheetHeaderProtocol = parsedStyleSheet2StyleSheetHeaderProtocol;
/**
 * 遍历 CSSRule AST
 */
function traversePageCSSRules(callback) {
    return __awaiter(this, void 0, void 0, function () {
        var styleSheets;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getParsedStyleSheets()];
                case 1:
                    styleSheets = _b.sent();
                    styleSheets.forEach(function (_b, styleSheetId) {
                        var postCSSRoot = _b.postCSSRoot;
                        (postCSSRoot.nodes || []).forEach(function (node) {
                            if (node.type === 'rule') {
                                callback(node, styleSheetId);
                            }
                        });
                    });
                    return [2 /*return*/];
            }
        });
    });
}
exports.traversePageCSSRules = traversePageCSSRules;
function elementMatches(domNode, selectorText) {
    var matches = domNode.matches || domNode.webkitMatchesSelector;
    if (matches) {
        try {
            return matches.call(domNode, selectorText);
        }
        catch (e) {
            return false;
        }
    }
    return false;
}
exports.elementMatches = elementMatches;
/**
 * CSS 文本生成 CSS.CSSStyle
 * @param {string} cssText
 * @param {string} styleSheetId
 */
function cssText2CSSStyleProtocol(cssText, styleSheetId) {
    var styleRoot;
    var cssProperties = [];
    var result = {
        cssProperties: cssProperties,
        shorthandEntries: [],
    };
    try {
        styleRoot = postcss_1.default.parse(cssText);
    }
    catch (err) {
        monitor.logJSError(err);
        return result;
    }
    (styleRoot.nodes || []).forEach(function (childNode) {
        var propertyItem = postCSSChildNode2CSSPropertyProtocol(childNode, styleSheetId);
        if (propertyItem) {
            cssProperties.push(propertyItem);
        }
    });
    if (styleSheetId) {
        result.styleSheetId = styleSheetId;
        // 传递真实文本值，缺少则无法编辑
        result.cssText = cssText;
        result.range = {
            endColumn: cssText.length,
            endLine: 0,
            startColumn: 0,
            startLine: 0,
        };
    }
    return result;
}
exports.cssText2CSSStyleProtocol = cssText2CSSStyleProtocol;
function getInlineStyle4Fiber(domNode, nodeId) {
    if (domNode && domNode.nodeType === Node.ELEMENT_NODE) {
        return cssText2CSSStyleProtocol(domNode.getAttribute('style') || '', "2000." + nodeId);
    }
}
exports.getInlineStyle4Fiber = getInlineStyle4Fiber;
function getInheritedStyle(vnode, inherited) {
    var _b;
    return __awaiter(this, void 0, void 0, function () {
        var parentNodeId, parentNode, matchedStyles_1, domNode_1, inlineStyleResult, grandParentId, grandParentNode;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    parentNodeId = vnode.parentId;
                    if (!parentNodeId) {
                        return [2 /*return*/, inherited];
                    }
                    parentNode = elementStore_1.default.get(parentNodeId);
                    if (!parentNode) return [3 /*break*/, 3];
                    matchedStyles_1 = {
                        matchedCSSRules: [],
                    };
                    domNode_1 = parentNode.element;
                    if (!(domNode_1 && domNode_1.nodeType === Node.ELEMENT_NODE)) return [3 /*break*/, 2];
                    return [4 /*yield*/, traversePageCSSRules(function (rule, styleSheetId) {
                            var _b;
                            if (elementMatches(domNode_1, rule.selector)) {
                                (_b = matchedStyles_1 === null || matchedStyles_1 === void 0 ? void 0 : matchedStyles_1.matchedCSSRules) === null || _b === void 0 ? void 0 : _b.push(postCSSRule2CSSRuleMatchProtocol(rule, domNode_1, styleSheetId));
                            }
                        })];
                case 1:
                    _c.sent();
                    inlineStyleResult = getInlineStyle4Fiber(domNode_1, parentNodeId);
                    if (inlineStyleResult) {
                        matchedStyles_1.inlineStyle = inlineStyleResult;
                    }
                    _c.label = 2;
                case 2:
                    try {
                        matchedStyles_1.matchedCSSRules = getSpecificitySortedRuleMatchList(
                        // @ts-ignore
                        matchedStyles_1.matchedCSSRules);
                    }
                    catch (err) {
                        monitor.logJSError(err);
                    }
                    inherited.push(matchedStyles_1);
                    grandParentId = (_b = parentNode.node) === null || _b === void 0 ? void 0 : _b.parentId;
                    if (grandParentId) {
                        grandParentNode = elementStore_1.default.get(grandParentId);
                        if (grandParentNode && grandParentNode.node) {
                            return [2 /*return*/, getInheritedStyle(grandParentNode.node, inherited)];
                        }
                    }
                    _c.label = 3;
                case 3: return [2 /*return*/, inherited];
            }
        });
    });
}
exports.getInheritedStyle = getInheritedStyle;
function getMatchedStylesForNode(nodeId, needInherited) {
    return __awaiter(this, void 0, void 0, function () {
        var node, matchedStyles, domNode_2, inlineStyleResult, collect, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    node = elementStore_1.default.get(nodeId);
                    matchedStyles = {
                        matchedCSSRules: [],
                    };
                    if (!node) return [3 /*break*/, 2];
                    domNode_2 = node.element;
                    if (!(domNode_2 && domNode_2.nodeType === Node.ELEMENT_NODE)) return [3 /*break*/, 2];
                    return [4 /*yield*/, traversePageCSSRules(function (rule, styleSheetId) {
                            var _b;
                            if (elementMatches(domNode_2, rule.selector)) {
                                (_b = matchedStyles === null || matchedStyles === void 0 ? void 0 : matchedStyles.matchedCSSRules) === null || _b === void 0 ? void 0 : _b.push(postCSSRule2CSSRuleMatchProtocol(rule, domNode_2, styleSheetId));
                            }
                        })];
                case 1:
                    _c.sent();
                    inlineStyleResult = getInlineStyle4Fiber(domNode_2, nodeId);
                    if (inlineStyleResult) {
                        matchedStyles.inlineStyle = inlineStyleResult;
                    }
                    _c.label = 2;
                case 2:
                    if (lodash_1.get(matchedStyles, 'matchedCSSRules.length')) {
                        try {
                            matchedStyles.matchedCSSRules = getSpecificitySortedRuleMatchList(
                            // @ts-ignore
                            matchedStyles.matchedCSSRules);
                        }
                        catch (err) {
                            monitor.logJSError(err);
                        }
                    }
                    if (!(needInherited && node.node)) return [3 /*break*/, 4];
                    collect = [];
                    _b = matchedStyles;
                    return [4 /*yield*/, getInheritedStyle(node.node, collect)];
                case 3:
                    _b.inherited = _c.sent();
                    _c.label = 4;
                case 4: return [2 /*return*/, matchedStyles];
            }
        });
    });
}
exports.getMatchedStylesForNode = getMatchedStylesForNode;
/**
 * 按 specificify 做一次排序，权重高的放在后面
 * @param {Array<IDevtoolProtocolTypeCSSRuleMatch>} list
 * @returns {Array<IDevtoolProtocolTypeCSSRuleMatch>}
 */
function getSpecificitySortedRuleMatchList(list) {
    return __spreadArray([], list).sort(function (a, b) {
        return getSelectorSepcificity4RuleMatch(a) - getSelectorSepcificity4RuleMatch(b);
    });
}
exports.getSpecificitySortedRuleMatchList = getSpecificitySortedRuleMatchList;
/**
 * PostCSS.Rule 生成 CSS.RuleMatch
 * @param {PostCSS.Rule} ruleNode
 * @param {Element} domNode 观察的目标 DOM 结点，用于匹配 rule selector
 * @param {string} styleSheetId optional，如果为空，则 Styles 面板不能进入编辑态
 * @param {RegExp} pseudoRe optional, 匹配伪元素的正则表达式，如果存在，则匹配伪元素选择器
 */
function postCSSRule2CSSRuleMatchProtocol(ruleNode, domNode, styleSheetId, pseudoRe) {
    var matchingSelectors = [];
    if (ruleNode.selectors.length > 1) {
        ruleNode.selectors.forEach(function (item, index) {
            if (pseudoRe
                ? pseudoRe.test(item) && elementMatches(domNode, item.replace(pseudoRe, ''))
                : elementMatches(domNode, item)) {
                matchingSelectors.push(index);
            }
        });
    }
    else {
        matchingSelectors.push(0);
    }
    var styleSheetItem;
    if (styleSheetId) {
        styleSheetItem = parsedStyleSheets.get(styleSheetId);
    }
    var origin = lodash_1.get(styleSheetItem, 'origin');
    return {
        matchingSelectors: matchingSelectors,
        rule: {
            origin: origin || 'regular',
            selectorList: {
                selectors: ruleNode.selectors.map(function (v) { return ({
                    range: styleSheetId
                        ? {
                            endColumn: lodash_1.get(ruleNode, 'source.start.column') + ruleNode.selector.length - 1,
                            endLine: lodash_1.get(ruleNode, 'source.start.line') - 1,
                            startColumn: lodash_1.get(ruleNode, 'source.start.column') - 1,
                            startLine: lodash_1.get(ruleNode, 'source.start.line') - 1,
                        }
                        : undefined,
                    text: v,
                }); }),
                text: ruleNode.selector,
            },
            style: postCSSRule2CSSStyleProtocol(ruleNode, styleSheetId),
            styleSheetId: styleSheetId,
        },
    };
}
exports.postCSSRule2CSSRuleMatchProtocol = postCSSRule2CSSRuleMatchProtocol;
/**
 * PostCSS 规则结点生成 CSS.CSSStyle
 * @param {PostCSS.Rule} ruleNode PostCSS 一条规则结点的 AST Node
 * @param {string} 所属样式表 ID
 */
function postCSSRule2CSSStyleProtocol(ruleNode, styleSheetId) {
    var _b = ruleNode.nodes, nodes = _b === void 0 ? [] : _b, first = ruleNode.first, last = ruleNode.last;
    var firstNode = nodes[0];
    var newLineNum = 0;
    /**
     * .pgae {<
     *   color: red;<
     * <}
     * 在多行的场景下，cssText 包含顶部的换行符, startLine 从第一个换行符算起
     */
    if (firstNode && firstNode.raws.before) {
        newLineNum = firstNode.raws.before.split('\n').length - 1;
    }
    var cssProperties = [];
    nodes.forEach(function (childNode) {
        var propertyItem = postCSSChildNode2CSSPropertyProtocol(childNode, styleSheetId);
        if (propertyItem) {
            cssProperties.push(propertyItem);
        }
    });
    var result = {
        cssProperties: cssProperties,
        shorthandEntries: [],
    };
    if (styleSheetId) {
        result.styleSheetId = styleSheetId;
        // cssText 不包括选择器: color: red; background: blue;
        result.cssText = postcss_1.default.root({
            nodes: nodes,
            raws: {
                semicolon: ruleNode.raws.semicolon,
            },
        }).toString();
        result.range = {
            endColumn: last ? lodash_1.get(last, 'source.end.column') : 0,
            endLine: last ? lodash_1.get(last, 'source.end.line') - 1 : 0,
            // eslint-disable-next-line no-nested-ternary
            startColumn: newLineNum ? 0 : first ? lodash_1.get(first, 'source.start.column') - 1 : 0,
            startLine: first ? lodash_1.get(first, 'source.start.line', 0) - 1 - newLineNum : 0,
        };
    }
    return result;
}
exports.postCSSRule2CSSStyleProtocol = postCSSRule2CSSStyleProtocol;
/**
 * PostCSS 属性结点生成 CSS.CSSProperty
 * @param {PostCSS.ChildNode} childNode PostCSS 一条规则属性的 AST Node
 */
function postCSSChildNode2CSSPropertyProtocol(childNode, styleSheetId) {
    var propertyStruct = {};
    if (childNode.type === 'decl') {
        propertyStruct = {
            disabled: false,
            important: Boolean(childNode.important),
            name: childNode.prop,
            value: formatDeclarationValue(childNode),
        };
    }
    else if (childNode.type === 'comment') {
        var commentProperyNode = {};
        try {
            var commentRoot = postcss_1.default.parse(childNode.text);
            if (commentRoot.first && commentRoot.first.type === 'decl') {
                commentProperyNode = commentRoot.first;
            }
        }
        catch (err) {
            logger_1.error(err);
            monitor.logJSError(err);
        }
        // 非样式属性注释
        if (!commentProperyNode) {
            return;
        }
        propertyStruct = {
            disabled: true,
            name: commentProperyNode.prop,
            value: formatDeclarationValue(commentProperyNode),
        };
    }
    if (propertyStruct && styleSheetId) {
        propertyStruct.range = {
            endColumn: lodash_1.get(childNode.source, 'end.column'),
            endLine: lodash_1.get(childNode.source, 'end.line') - 1,
            startColumn: lodash_1.get(childNode.source, 'start.column') - 1,
            startLine: lodash_1.get(childNode.source, 'start.line') - 1,
        };
        propertyStruct.text = childNode.toString(); // 无结尾分号
    }
    return propertyStruct;
}
exports.postCSSChildNode2CSSPropertyProtocol = postCSSChildNode2CSSPropertyProtocol;
function getInlineStylesForNode(nodeId) {
    var node = elementStore_1.default.get(nodeId);
    var inlineStyle;
    if (node) {
        var domNode = node.element;
        if (domNode && domNode.nodeType === Node.ELEMENT_NODE) {
            // 约定内联样式 ID 以 2000 开头
            var styleSheetId = "2000." + nodeId;
            inlineStyle = {
                cssProperties: [],
                cssText: '',
                range: {
                    endColumn: 0,
                    endLine: 0,
                    startColumn: 0,
                    startLine: 0,
                },
                shorthandEntries: [],
                styleSheetId: styleSheetId,
            };
            var inlineStyleResult = getInlineStyle4Fiber(node.element, nodeId);
            if (inlineStyleResult) {
                inlineStyle = inlineStyleResult;
            }
        }
    }
    return inlineStyle;
}
exports.getInlineStylesForNode = getInlineStylesForNode;
function setStyleTexts(message) {
    var returnStyles = [];
    var inlineStyleIdNS = '2000.';
    message.edits.forEach(function (item) {
        var styleSheetId = item.styleSheetId;
        if (styleSheetId.indexOf(inlineStyleIdNS) === 0) {
            var nodeId = Number(styleSheetId.replace(inlineStyleIdNS, ''));
            var node = elementStore_1.default.get(nodeId);
            if (node) {
                var domNode = node.element;
                if (domNode && domNode.nodeType === Node.ELEMENT_NODE) {
                    domNode.setAttribute('style', item.text);
                    var inlineStyleResult = getInlineStyle4Fiber(domNode, nodeId);
                    if (inlineStyleResult) {
                        returnStyles.push(inlineStyleResult);
                    }
                }
            }
        }
        else {
            // 0-based -> 1-based
            item.range.startLine += 1;
            item.range.startColumn += 1;
            var result = setStyleTexts4StyleSheet(styleSheetId, item.range, item.text);
            if (result) {
                returnStyles.push(result);
            }
        }
    });
    return returnStyles;
}
exports.setStyleTexts = setStyleTexts;
/**
 * 编辑样式表中某个 Rule 属性，用于实现 CSS.setStyleTexts 请求
 */
function setStyleTexts4StyleSheet(styleSheetId, range, text) {
    var _b, _c, _d, _e, _f;
    var parsedStyleSheet = getParsedStyleSheetById(styleSheetId);
    if (!parsedStyleSheet) {
        return;
    }
    var editingTextRoot;
    try {
        editingTextRoot = postcss_1.default.parse(text);
    }
    catch (err) {
        logger_1.error('setStyleTexts4StyleSheet parse text error', err);
        monitor.logJSError(err);
        return;
    }
    // rang 为第一个字符的位置，而 PostCSS.RuleNode 位置会包含第一个字符前的空行
    // 在非 minify 的样式表中两边口径不一致，需要判断换行数量，矫正 range 为 RuleNode 的位置
    var startLine = range.startLine, startColumn = range.startColumn;
    var newLineNum = (((_c = (_b = editingTextRoot.first) === null || _b === void 0 ? void 0 : _b.raws) === null || _c === void 0 ? void 0 : _c.before) || '').split('\n').length - 1;
    if (newLineNum) {
        startLine += newLineNum;
        startColumn = ((_f = (_e = (_d = editingTextRoot.first) === null || _d === void 0 ? void 0 : _d.source) === null || _e === void 0 ? void 0 : _e.start) === null || _f === void 0 ? void 0 : _f.column) || 1;
    }
    var postCSSRoot = parsedStyleSheet.postCSSRoot, ownerNode = parsedStyleSheet.ownerNode;
    // 遍历所有的 CSSRule，从中找出当前编辑的那个
    var newStyleProtocol;
    (postCSSRoot.nodes || []).some(function (ruleNode, ruleIndex) {
        var _b;
        if (ruleNode.type !== 'rule') {
            return false;
        }
        // 如果当前编辑样式的起点位置与某个 CSSRule 属性的起点位置相同，则认为是同一个
        var ruleFirstNodeSource = (_b = ruleNode.first) === null || _b === void 0 ? void 0 : _b.source;
        if (ruleFirstNodeSource &&
            ruleFirstNodeSource.start.line === startLine &&
            ruleFirstNodeSource.start.column === startColumn) {
            try {
                // 替换新的规则属性
                ruleNode.nodes = editingTextRoot.nodes;
                // 保留 last child 末尾分号
                ruleNode.raws.semicolon = editingTextRoot.raws.semicolon;
                // 生成新的样式文本
                setNodeTextContentByPrototype(ownerNode, postCSSRoot.toString());
                // 更新解析后的样式表
                refreshParsedStyleSheetById(styleSheetId);
                var newStyleRoot = (getParsedStyleSheetById(styleSheetId) || {}).postCSSRoot;
                newStyleProtocol = postCSSRule2CSSStyleProtocol(newStyleRoot.nodes[ruleIndex], styleSheetId);
            }
            catch (err) {
                logger_1.error(err);
                monitor.logJSError(err);
            }
            return true;
        }
        return false;
    });
    return newStyleProtocol;
}
exports.setStyleTexts4StyleSheet = setStyleTexts4StyleSheet;
/**
 * 重新解析某个 <style> 的 AST
 */
function refreshParsedStyleSheetById(styleSheetId) {
    var styleSheet = parsedStyleSheets.get(styleSheetId);
    if (!styleSheet)
        return;
    var ownerNode = styleSheet.ownerNode;
    if (ownerNode) {
        try {
            parsedStyleSheets.set(styleSheetId, {
                ownerNode: ownerNode,
                postCSSRoot: postcss_1.default.parse(ownerNode.textContent || ''),
                styleSheetId: styleSheetId,
                sourceUrl: styleSheet.sourceUrl,
                origin: styleSheet.origin,
                cssText: styleSheet.cssText,
            });
        }
        catch (err) {
            parsedStyleSheets.set(styleSheetId, {
                ownerNode: ownerNode,
                postCSSRoot: postcss_1.default.parse(''),
                styleSheetId: styleSheetId,
                sourceUrl: styleSheet.sourceUrl,
                origin: styleSheet.origin,
                cssText: styleSheet.cssText,
            });
            logger_1.error(err);
            monitor.logJSError(err);
        }
    }
}
exports.refreshParsedStyleSheetById = refreshParsedStyleSheetById;
function setNodeTextContentByPrototype(node, text) {
    var _b;
    var descriptor = Object.getOwnPropertyDescriptor(Node.prototype, 'textContent');
    if (!descriptor)
        return;
    return (_b = descriptor === null || descriptor === void 0 ? void 0 : descriptor.set) === null || _b === void 0 ? void 0 : _b.call(node, text);
}
exports.setNodeTextContentByPrototype = setNodeTextContentByPrototype;
function formatDeclarationValue(node) {
    return node.important ? "" + node.value + (node.raws.important || ' !important') : node.value;
}
function getSelectorSepcificity4RuleMatch(ruleMatch) {
    // 大多数情况下，一条规则只有一个选择器匹配
    if (ruleMatch.matchingSelectors.length === 1) {
        return selectorSpecificity(ruleMatch.rule.selectorList.selectors[0].text);
    }
    else {
        // 如果一条规则同时有多个选择器命中，取权重较大的
        return maxSpecificity(ruleMatch.matchingSelectors.map(function (idx) { return ruleMatch.rule.selectorList.selectors[idx].text; }));
    }
}
function maxSpecificity(selectorList) {
    return Math.max.apply(Math, selectorList.map(function (selector) { return selectorSpecificity(selector); }));
}
function selectorSpecificity(selector) {
    if (typeof selector === 'string' && selectorSepcificityCache[selector]) {
        return selectorSepcificityCache[selector];
    }
    var total = 0;
    var selectorNode = {};
    if (typeof selector === 'string') {
        selectorParser(function (root) {
            // 只选取第一个
            if (root.nodes && root.nodes.length) {
                selectorNode = root.nodes[0];
            }
        }).process(selector);
    }
    if (selectorNode.nodes && selectorNode.nodes.length) {
        selectorNode.nodes.forEach(function (node) {
            total = addSpecificities(total, simpleSelectorSpecificityInternal(node));
        });
    }
    if (typeof selector === 'string') {
        selectorSepcificityCache[selector] = total;
    }
    return total;
}
function simpleSelectorSpecificityInternal(simpleSelectorNode) {
    if (!simpleSelectorNode.value)
        return 0;
    var pseudoClassType = (simpleSelectorNode.value.match(/^:+(\w+)/) || [])[1];
    switch (simpleSelectorNode.type) {
        case 'id':
            return ESelectorSpecificityIncrement.ClassA;
        case 'pseudo':
            switch (pseudoClassType) {
                case 'is':
                case 'match':
                case 'not':
                    return maxSpecificity(simpleSelectorNode.nodes);
                case 'where':
                    return 0;
                case 'nth-child':
                case 'nth-last-child':
                    return addSpecificities(ESelectorSpecificityIncrement.ClassB, simpleSelectorNode.nodes ? maxSpecificity(simpleSelectorNode.nodes) : 0);
                case 'before':
                case 'after':
                    return ESelectorSpecificityIncrement.ClassC;
                default:
                    break;
            }
            return ESelectorSpecificityIncrement.ClassB;
        case 'class':
        case 'attribute':
            return ESelectorSpecificityIncrement.ClassB;
        case 'tag':
            return ESelectorSpecificityIncrement.ClassC;
        default:
            break;
    }
    return 0;
}
/**
 * Specificity 加和算法
 * https://github.com/WebKit/WebKit/blob/main/Source/WebCore/css/CSSSelector.cpp#L167
 */
function addSpecificities(a, b) {
    var total = a;
    var newIdValue = getIdValue(b);
    if (isIdOverflow(getIdValue(total) + newIdValue)) {
        total = setIdFull(total);
    }
    else {
        total += newIdValue;
    }
    var newClassValue = getClassValue(b);
    if (isClassOverflow(getClassValue(total) + newClassValue)) {
        total = setClassFull(total);
    }
    else {
        total += newClassValue;
    }
    var newElementValue = getElementValue(b);
    if (isElementOverflow(getElementValue(total) + newElementValue)) {
        total = setElementFull(total);
    }
    else {
        total += newElementValue;
    }
    return total;
}
var idMask = 0xff0000; // 1111 1111 0000 0000 0000 0000
var classMask = 0xff00; //          1111 1111 0000 0000
var elementMask = 0xff; //                    1111 1111
function getIdValue(b) {
    return b & idMask;
}
function isIdOverflow(b) {
    return b & ~idMask;
}
function setIdFull(b) {
    return b | idMask;
}
function getClassValue(b) {
    return b & classMask;
}
function isClassOverflow(b) {
    return b & ~classMask;
}
function setClassFull(b) {
    return b | classMask;
}
function getElementValue(b) {
    return b & elementMask;
}
function isElementOverflow(b) {
    return b & ~elementMask;
}
function setElementFull(b) {
    return b | elementMask;
}
