"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg) {
    var _a;
    var params = msg.params;
    if (params && params.nodeId) {
        var node = elementStore_1.default.get(params.nodeId);
        var element = node.element;
        (_a = element.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(element);
    }
});
