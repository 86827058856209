"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectStore = exports.injectedScriptId = void 0;
exports.injectedScriptId = 1;
var ObjectStore = /** @class */ (function () {
    function ObjectStore() {
        this.id = 0;
        // TODO: 及时释放，作用域销毁后就应该释放
        this.id2Object = new Map();
        this.object2Id = new Map();
    }
    ObjectStore.prototype.save = function (obj) {
        if (this.object2Id.has(obj)) {
            return this.object2Id.get(obj);
        }
        else {
            var objectId = this.getNextObjectId();
            this.id2Object.set(objectId, obj);
            this.object2Id.set(obj, objectId);
            return objectId;
        }
    };
    ObjectStore.prototype.query = function (objectId) {
        var rt = this.id2Object.get(objectId);
        return rt;
    };
    ObjectStore.prototype.clear = function (objectId) {
        var object = this.id2Object.get(objectId);
        if (object) {
            this.id2Object.delete(objectId);
            this.object2Id.delete(object);
        }
    };
    ObjectStore.prototype.clearAll = function () {
        this.id2Object.clear();
        this.object2Id.clear();
    };
    ObjectStore.prototype.getLastObject = function () {
        return this.query("{\"injectedScriptId\":" + exports.injectedScriptId + ",\"id\":" + this.getLastScriptId() + "}");
    };
    ObjectStore.prototype.getLastScriptId = function () {
        return this.id - 1;
    };
    ObjectStore.prototype.getObjectIdById = function (id) {
        return "{\"injectedScriptId\":" + exports.injectedScriptId + ",\"id\":" + id + "}";
    };
    ObjectStore.prototype.getNextObjectId = function () {
        this.id++;
        return "{\"injectedScriptId\":" + exports.injectedScriptId + ",\"id\":" + this.id + "}";
    };
    return ObjectStore;
}());
exports.ObjectStore = ObjectStore;
exports.default = new ObjectStore();
