"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/member-ordering */
var lodash_1 = require("lodash");
var uuid = require("uuid");
var network_1 = require("../../utils/network");
var NetworkStore = /** @class */ (function () {
    function NetworkStore() {
        this.reqList = {};
        this._xhrOpen = function () { };
        this._xhrSend = function () { };
        this._xhrSetRequestHeader = function () { };
    }
    NetworkStore.prototype.set = function (requestId, data) {
        this.reqList[requestId] = data;
    };
    NetworkStore.prototype.get = function (requestId) {
        return this.reqList[requestId];
    };
    NetworkStore.prototype.getStore = function () {
        return this.reqList;
    };
    /**
     * add or update a request item by request ID
     */
    NetworkStore.prototype.updateRequest = function (id, data) {
        // update item
        var item = this.reqList[id] || {};
        // eslint-disable-next-line guard-for-in
        for (var key in data) {
            item[key] = data[key];
        }
        this.set(id, item);
        // console.log(item);
    };
    /**
     * mock XMLHttpRequest
     */
    NetworkStore.prototype.mockXHR = function (requestHandler) {
        var _XMLHttpRequest = window.XMLHttpRequest;
        if (!_XMLHttpRequest) {
            return;
        }
        var that = this;
        var _open = window.XMLHttpRequest.prototype.open;
        var _send = window.XMLHttpRequest.prototype.send;
        var _setRequestHeader = window.XMLHttpRequest.prototype.setRequestHeader;
        that._xhrOpen = _open;
        that._xhrSend = _send;
        that._xhrSetRequestHeader = _setRequestHeader;
        // mock open()
        window.XMLHttpRequest.prototype.open = function () {
            var _this = this;
            var XMLReq = this;
            var args = [].slice.call(arguments);
            var method = args[0];
            var url = args[1];
            var id = that.getUniqueID();
            var timer = null;
            // may be used by other functions
            XMLReq._requestID = id;
            XMLReq._method = method;
            XMLReq._url = url;
            // mock onreadystatechange
            var _onreadystatechange = XMLReq.onreadystatechange || function () { };
            var onreadystatechange = function () {
                var item = that.reqList[id] || { id: id };
                // update status
                item.readyState = XMLReq.readyState;
                item.responseType = XMLReq.responseType;
                item.requestType = 'XHR';
                // update data by readyState
                switch (XMLReq.readyState) {
                    case 0: // UNSENT
                        item.status = 0;
                        item.statusText = 'Pending';
                        if (!item.startTime) {
                            item.startTime = (+new Date());
                        }
                        break;
                    case 1: // OPENED
                        item.status = 0;
                        item.statusText = 'Pending';
                        if (!item.startTime) {
                            item.startTime = (+new Date());
                        }
                        break;
                    case 2: // HEADERS_RECEIVED
                        item.status = XMLReq.status;
                        item.statusText = 'Loading';
                        item.header = {};
                        var header = XMLReq.getAllResponseHeaders() || '';
                        var headerArr = header.split('\n');
                        // extract plain text to key-value format
                        for (var i = 0; i < headerArr.length; i++) {
                            var line = headerArr[i];
                            if (!line) {
                                continue;
                            }
                            var arr = line.split(': ');
                            var key = arr[0];
                            var value = arr.slice(1).join(': ');
                            item.header[key] = value;
                        }
                        break;
                    case 3: // LOADING
                        item.status = XMLReq.status;
                        item.statusText = 'Loading';
                        break;
                    case 4: // DONE
                        clearInterval(timer);
                        item.status = XMLReq.status;
                        item.statusText = XMLReq.statusText || 'OK'; // show status code when request completed
                        item.endTime = +new Date();
                        item.costTime = item.endTime - (item.startTime || item.endTime);
                        item.response = {
                            base64Encoded: false,
                            body: XMLReq.response,
                        };
                        break;
                    default:
                        clearInterval(timer);
                        item.status = XMLReq.status;
                        item.statusText = 'Unknown';
                        break;
                }
                // update response by responseType
                switch (XMLReq.responseType) {
                    case '':
                    case 'text':
                        // try to parse JSON
                        if (lodash_1.isString(XMLReq.response)) {
                            try {
                                item.response = {
                                    base64Encoded: false,
                                    body: JSON.parse(XMLReq.response),
                                };
                                item.response = {
                                    base64Encoded: false,
                                    body: JSON.stringify(item.response.body, null, 1),
                                };
                            }
                            catch (e) {
                                // not a JSON string
                                item.response = {
                                    base64Encoded: false,
                                    body: XMLReq.response,
                                };
                            }
                        }
                        else if (typeof XMLReq.response !== 'undefined') {
                            item.response = {
                                base64Encoded: false,
                                body: Object.prototype.toString.call(XMLReq.response),
                            };
                        }
                        break;
                    case 'json':
                        if (typeof XMLReq.response !== 'undefined') {
                            item.response = {
                                base64Encoded: false,
                                body: JSON.stringify(XMLReq.response, null, 1),
                            };
                        }
                        break;
                    case 'blob':
                    case 'document':
                    case 'arraybuffer':
                    default:
                        if (typeof XMLReq.response !== 'undefined') {
                            item.response = {
                                base64Encoded: false,
                                body: Object.prototype.toString.call(XMLReq.response),
                            };
                        }
                        break;
                }
                that.updateRequest(id, item);
                if (XMLReq.readyState === 4) {
                    requestHandler.onResponseReceived(item); // send CDP
                    // requestHandler.onLoadingFinished(item);
                }
                return _onreadystatechange.apply(XMLReq, arguments);
            };
            XMLReq.onreadystatechange = onreadystatechange;
            // some 3rd-libraries will change XHR's default function
            // so we use a timer to avoid lost tracking of readyState
            var preState = -1;
            timer = setInterval(function () {
                if (preState != XMLReq.readyState) {
                    preState = XMLReq.readyState;
                    onreadystatechange.call(XMLReq);
                }
            }, 10);
            XMLReq.addEventListener('error', function (ev) {
                requestHandler.onLoadingFailed(that.get(_this._requestID));
                throw new network_1.NetworkError(method + " " + url + " Failed. " + lodash_1.get(ev, 'target.responseText', ''));
            });
            XMLReq.addEventListener('loadend', function () {
                requestHandler.onLoadingFinished(that.get(_this._requestID));
                if (network_1.isErrorStatus(XMLReq.status)) {
                    throw new network_1.NetworkError(method + " " + url + " Failed. " + XMLReq.status + " " + XMLReq.statusText);
                }
            });
            XMLReq.addEventListener('abort', function (ev) {
                requestHandler.onLoadingFinished(that.get(_this._requestID));
                throw new network_1.NetworkError(method + " " + url + " Failed. " + lodash_1.get(ev, 'target.responseText', ''));
            });
            return _open.apply(XMLReq, args);
        };
        // mock setRequestHeader()
        window.XMLHttpRequest.prototype.setRequestHeader = function () {
            var XMLReq = this;
            var args = [].slice.call(arguments);
            var item = that.reqList[XMLReq._requestID];
            if (item) {
                if (!item.requestHeader) {
                    item.requestHeader = {};
                }
                item.requestHeader[args[0]] = args[1];
            }
            return _setRequestHeader.apply(XMLReq, args);
        };
        // mock send()
        window.XMLHttpRequest.prototype.send = function () {
            var XMLReq = this;
            var args = [].slice.call(arguments);
            var data = args[0];
            var _a = XMLReq, _b = _a._requestID, _requestID = _b === void 0 ? that.getUniqueID() : _b, _url = _a._url, _method = _a._method;
            var item = that.reqList[_requestID] || { id: _requestID };
            var isInternalHost = XMLReq['__internal_request__'];
            item.method = _method ? _method.toUpperCase() : 'GET';
            item.isInternalRequest = isInternalHost || false;
            var query = _url ? _url.split('?') : []; // a.php?b=c&d=?e => ['a.php', 'b=c&d=', 'e']
            item.url = _url || '';
            item.name = query.shift() || ''; // => ['b=c&d=', 'e']
            item.name = item.name.replace(new RegExp('[/]*$'), '').split('/').pop() || '';
            if (query.length > 0) {
                item.name += "?" + query;
                item.getData = {};
                query = query.join('?'); // => 'b=c&d=?e'
                query = query.split('&'); // => ['b=c', 'd=?e']
                for (var _i = 0, query_1 = query; _i < query_1.length; _i++) {
                    var q = query_1[_i];
                    q = q.split('=');
                    item.getData[q[0]] = decodeURIComponent(q[1]);
                }
            }
            if (item.method == 'POST') {
                // save POST data
                if (lodash_1.isString(data)) {
                    try { // '{a:1}' => try to parse as json
                        item.postData = JSON.parse(data);
                    }
                    catch (e) { // 'a=1&b=2' => try to parse as query
                        var arr = data.split('&');
                        item.postData = {};
                        for (var _c = 0, arr_1 = arr; _c < arr_1.length; _c++) {
                            var q = arr_1[_c];
                            var qr = q.split('=');
                            item.postData[qr[0]] = qr[1];
                        }
                    }
                }
                else if (lodash_1.isPlainObject(data)) {
                    item.postData = data;
                }
                else {
                    item.postData = '[object Object]';
                }
            }
            that.updateRequest(_requestID, item);
            requestHandler.onRequestWillBeSent(item); // send CDP
            return _send.apply(XMLReq, args);
        };
    };
    /**
     * mock fetch request
     */
    NetworkStore.prototype.mockFetch = function (requestHandler) {
        var _fetch = window.fetch;
        if (!_fetch) {
            return;
        }
        var that = this;
        this._fetch = _fetch;
        window.fetch = function (input, init) {
            var id = that.getUniqueID();
            var item = that.reqList[id] || { id: id } || { id: id };
            that.updateRequest(id, item);
            // that.reqList[id] = item;
            var url;
            var method = 'GET';
            var requestHeader;
            var _fetchReponse;
            // handle `input` content
            if (lodash_1.isString(input)) { // when `input` is a string
                method = (init === null || init === void 0 ? void 0 : init.method) || 'GET';
                url = that.getURL(input);
                requestHeader = init === null || init === void 0 ? void 0 : init.headers;
            }
            else { // when `input` is a `Request` object
                method = input.method || 'GET';
                url = that.getURL(input.url);
                requestHeader = input.headers;
            }
            item.id = id;
            item.method = method;
            item.requestType = 'Fetch';
            item.requestHeader = requestHeader;
            item.url = url.toString();
            item.name = (url.pathname.split('/').pop() || '') + url.search;
            item.status = 0;
            item.statusText = 'Pending';
            if (!item.startTime) { // UNSENT
                item.startTime = (+new Date());
            }
            if (Object.prototype.toString.call(requestHeader) === '[object Headers]') {
                item.requestHeader = {};
                requestHeader.forEach(function (value, key) {
                    item.requestHeader[key] = value;
                });
            }
            else {
                item.requestHeader = requestHeader;
            }
            // save GET data
            if (url.search) {
                item.getData = {};
                url.searchParams.forEach(function (value, key) {
                    item.getData[key] = value;
                });
            }
            // save POST data
            if (item.method === 'POST') {
                if (lodash_1.isString(input)) { // when `input` is a string
                    item.postData = that.getFormattedBody(init === null || init === void 0 ? void 0 : init.body);
                }
                else { // when `input` is a `Request` object
                    // cannot get real type of request's body, so just display "[object Object]"
                    item.postData = '[object Object]';
                }
            }
            var request = lodash_1.isString(input) ? url.toString() : input;
            requestHandler.onRequestWillBeSent(item); // send CDP
            return _fetch(request, init).then(function (response) {
                _fetchReponse = response;
                item.endTime = +new Date();
                item.costTime = item.endTime - (item.startTime || item.endTime);
                item.status = response.status;
                item.statusText = String(response.status);
                item.header = {};
                response.headers.forEach(function (value, key) {
                    item.header[key] = value;
                });
                item.readyState = 4;
                // parse response body by Content-Type
                var contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    item.responseType = 'json';
                    return response.clone().text();
                }
                else if (contentType && contentType.includes('text/html')) {
                    item.responseType = 'text';
                    return response.clone().text();
                }
                else {
                    item.responseType = '';
                    return '[object Object]';
                }
            }).then(function (responseBody) {
                // save response body
                switch (item.responseType) {
                    case 'json':
                        try {
                            // try to parse response as JSON
                            item.response = {
                                base64Encoded: false,
                                body: JSON.parse(responseBody),
                            };
                            item.response = {
                                base64Encoded: false,
                                body: JSON.stringify(item.response.body, null, 1),
                            };
                        }
                        catch (e) {
                            // not real JSON, use 'text' as default type
                            item.response = {
                                base64Encoded: false,
                                body: responseBody,
                            };
                            item.responseType = 'text';
                        }
                        break;
                    case 'text':
                    default:
                        item.response = {
                            base64Encoded: false,
                            body: responseBody,
                        };
                        break;
                }
                requestHandler.onResponseReceived(item); // send CDP
                return _fetchReponse;
            }).catch(function (err) {
                if (!item.endTime) {
                    item.endTime = +new Date();
                }
                item.response = {
                    base64Encoded: false,
                    body: '',
                };
                requestHandler.onLoadingFailed(item);
                throw err;
            })
                .finally(function () {
                // _fetchReponse = undefined;
                that.updateRequest(id, item);
                requestHandler.onLoadingFinished(item);
            });
        };
    };
    NetworkStore.prototype.getUniqueID = function (prefix) {
        if (prefix === void 0) { prefix = ''; }
        return "__dd_" + prefix + "_" + uuid.v4();
    };
    NetworkStore.prototype.getFormattedBody = function (body) {
        if (!body) {
            return {};
        }
        var ret = {};
        var type = this.getPrototypeName(body);
        switch (type) {
            case 'String':
                try {
                    // try to parse as JSON
                    ret = JSON.parse(body);
                }
                catch (e) {
                    // not a json, return original string
                    ret = body;
                }
                break;
            case 'URLSearchParams':
                ret = {};
                body.forEach(function (value, key) {
                    ret[key] = value;
                });
                break;
            default:
                ret = "[object " + type + "]";
                break;
        }
        return ret;
    };
    NetworkStore.prototype.getPrototypeName = function (value) {
        return Object.prototype.toString.call(value).replace(/\[object (.*)\]/, '$1');
    };
    NetworkStore.prototype.getURL = function (urlString) {
        if (urlString === void 0) { urlString = ''; }
        if (urlString.startsWith('//')) {
            var baseUrl = new URL(window.location.href);
            urlString = "" + baseUrl.protocol + urlString;
        }
        if (urlString.startsWith('http')) {
            return new URL(urlString);
        }
        else {
            return new URL(urlString, window.location.href);
        }
    };
    return NetworkStore;
}());
exports.default = new NetworkStore();
