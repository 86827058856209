"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (msg, socket) {
    // const {
    //   params,
    // } = msg;
    // const {
    //   expression,
    // } = params;
    // const { error, scriptId, hash } = callFn(expression);
    // // @ts-ignore
    // if (error && error.wasThrown) {
    //   const exceptionDetails = {
    //     columnNumber: 0,
    //     exception: getConsoleArg(error, scriptId),
    //     exceptionId: scriptId,
    //     lineNumber: 0,
    //     scriptId: scriptId.toString(),
    //     text: 'Uncaught',
    //   };
    //   socket.response<Runtime.compileScript>({
    //     id: msg.id,
    //     result: {
    //       exceptionDetails,
    //     },
    //   });
    //   return;
    // }
    // socket.fireEvent<DebuggerEvent.scriptParsed>({
    //   method: DebuggerEvent.scriptParsed,
    //   params: {
    //     hash,
    //     startColumn: 0,
    //     endColumn: 0,
    //     startLine: 0,
    //     endLine: 0,
    //     executionContextId,
    //     executionContextAuxData: {
    //       frameId: '1.0',
    //       isDefault: true,
    //     },
    //     scriptId: `${scriptId}`,
    //     hasSourceURL: false,
    //     isLiveEdit: false,
    //     sourceMapURL: '',
    //     url: '',
    //   },
    // });
    socket.response({
        id: msg.id,
        result: {},
    });
});
