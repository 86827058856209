"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg) {
    var params = msg.params;
    var nodeId = params.nodeId, targetNodeId = params.targetNodeId;
    var node = elementStore_1.default.get(nodeId); // 待克隆节点
    var targetNode = elementStore_1.default.get(targetNodeId);
    if (node.element) {
        var cloneNode = elementStore_1.default.cloneNode(node.element);
        if (targetNode.element) {
            targetNode.element.appendChild(cloneNode === null || cloneNode === void 0 ? void 0 : cloneNode.element);
        }
    }
});
