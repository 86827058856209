"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElementStore = exports.ROOT_ELEMENT_NODE_ID = exports.store = void 0;
var isIgnoredElement_1 = require("../../utils/isIgnoredElement");
exports.store = {};
// 自增id，获取到一个element，incId会自增1
var incId = 1;
exports.ROOT_ELEMENT_NODE_ID = 1;
var ElementStore = /** @class */ (function () {
    function ElementStore() {
    }
    ElementStore.prototype.set = function (nodeId, data) {
        exports.store[nodeId] = data;
    };
    ElementStore.prototype.get = function (nodeId) {
        return exports.store[nodeId];
    };
    ElementStore.prototype.getStore = function () {
        return exports.store;
    };
    ElementStore.prototype.cloneNode = function (elem) {
        var vnode = elem['__vnode__'];
        if (!vnode)
            return;
        var parentId = vnode.parentId;
        var cloneElement = elem.cloneNode(true);
        var node = this.generateElementNode(cloneElement, parentId);
        return {
            node: node,
            element: cloneElement,
        };
    };
    ElementStore.prototype.generateElementNode = function (elem, parentId) {
        if (isIgnoredElement_1.default(elem))
            return;
        var elemNode = elem['__vnode__'];
        var nodeId;
        if (elemNode && elemNode.nodeId) {
            nodeId = elemNode.nodeId;
        }
        else {
            nodeId = ++incId;
        }
        incId += 1;
        // basic node info
        var node = elemNode || {
            nodeType: elem.nodeType,
            nodeName: elem.nodeName,
            localName: elem['localName'] || '',
            nodeValue: elem.nodeValue || '',
            nodeId: nodeId,
            backendNodeId: nodeId,
            children: [],
            baseURL: elem.baseURI,
            childNodeCount: elem['childElementCount'] || 0,
            attributes: Array.prototype.map.call(elem['attributes'] || [], function (item) {
                return [item.name, item.value];
            }).flat(),
        };
        if (parentId) {
            node.parentId = parentId;
        }
        if (elem.childNodes.length > 0) {
            node.children = [];
            for (var i = 0; i < elem.childNodes.length; i++) {
                var child = this.generateElementNode(elem.childNodes[i], node.nodeId);
                if (!child) {
                    continue;
                }
                if (!node.children) {
                    node.children = [];
                }
                node.children.push(child);
            }
        }
        elem['__vnode__'] = node;
        this.set(node.nodeId, {
            element: elem,
            node: node,
        });
        return node;
    };
    /**
     * 会产生两个副作用
     * 1. 往elem上注册__vnode__
     * 2. 往全局的Store中存储nodeId对{elem、node}的映射关系
     * 3. merge rootNode 和 elementNode
     *
     * @param elem
     * @param parentId
     * @returns
     */
    ElementStore.prototype.generateNode = function (elem) {
        var elementNode = this.generateElementNode(elem);
        var rootNode = {
            backendNodeId: exports.ROOT_ELEMENT_NODE_ID,
            childNodeCount: 1,
            children: [elementNode],
            localName: '',
            nodeId: exports.ROOT_ELEMENT_NODE_ID,
            nodeName: '#document',
            nodeType: 9,
            nodeValue: '',
        };
        this.set(exports.ROOT_ELEMENT_NODE_ID, {
            element: undefined,
            node: rootNode,
        });
        return rootNode;
    };
    return ElementStore;
}());
exports.ElementStore = ElementStore;
exports.default = new ElementStore();
