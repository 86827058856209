"use strict";
/**
 * 1. mutation observer 监测DOM变更
 * 2. time machine 处理redo和undo，队列中最多存放最近的xx个操作
 * [undoStateQueue] currentState [redoStateQueue]
 * 如果undo过程中，插入新的record，会将redoQueue清空，然后把新的record推入redoQueue中
 */
Object.defineProperty(exports, "__esModule", { value: true });
require("mutationobserver-shim");
var lodash_1 = require("lodash");
var elementStore_1 = require("../../lib/elementStore");
var getAttributeValue_1 = require("../../utils/getAttributeValue");
var getTextContent_1 = require("../../utils/getTextContent");
var isIgnoredElement_1 = require("../../utils/isIgnoredElement");
var logger_1 = require("../../utils/logger");
exports.default = (function (socket, handlerContext) {
    function onMutation(mutation) {
        logger_1.debug("MutationObserver catch per mutation " + mutation.type, mutation);
        switch (mutation.type) {
            case 'childList':
                if (mutation.removedNodes.length > 0) {
                    onChildRemove(mutation);
                }
                if (mutation.addedNodes.length > 0) {
                    onChildAdd(mutation);
                }
                break;
            case 'attributes':
                onAttributesChange(mutation);
                break;
            case 'characterData':
                onCharacterDataChange(mutation);
                break;
            default:
                break;
        }
    }
    function onChildAdd(mutation) {
        var $parent = mutation.target;
        elementStore_1.default.generateElementNode($parent);
        var parentNode = $parent['__vnode__'];
        if (!parentNode) {
            return;
        }
        for (var i = 0; i < mutation.addedNodes.length; i++) {
            var willAddedNode = mutation.addedNodes[i];
            if (isIgnoredElement_1.default(willAddedNode))
                continue;
            var willAddedVNode = lodash_1.get(willAddedNode, '__vnode__');
            if (willAddedVNode) {
                var previousSiblingElement = willAddedNode.previousElementSibling;
                var previousSiblingVNode = lodash_1.get(previousSiblingElement, '__vnode__');
                socket.fireEvent({
                    method: "DOM.childNodeInserted" /* childNodeInserted */,
                    params: {
                        parentNodeId: parentNode.nodeId,
                        previousNodeId: previousSiblingVNode ? lodash_1.get(previousSiblingVNode, 'nodeId', 0) : 0,
                        node: willAddedVNode,
                    },
                });
            }
        }
    }
    function onChildRemove(mutation) {
        var $parent = mutation.target;
        elementStore_1.default.generateElementNode($parent);
        var parentNode = $parent['__vnode__'];
        if (!parentNode) {
            return;
        }
        for (var i = 0; i < mutation.removedNodes.length; i++) {
            var willDeletedNode = mutation.removedNodes[i];
            var vnode = willDeletedNode['__vnode__'];
            if (!vnode) {
                continue;
            }
            socket.fireEvent({
                method: "DOM.childNodeRemoved" /* childNodeRemoved */,
                params: {
                    parentNodeId: parentNode.nodeId,
                    nodeId: vnode.nodeId,
                },
            });
        }
    }
    function onAttributesChange(mutation) {
        var $target = mutation.target;
        elementStore_1.default.generateElementNode($target);
        var vnode = $target['__vnode__'];
        if (!vnode) {
            return;
        }
        var attributeName = mutation.attributeName;
        if (attributeName) {
            var newValue = getAttributeValue_1.default(mutation.target, attributeName);
            socket.fireEvent({
                method: "DOM.attributeModified" /* attributeModified */,
                params: {
                    nodeId: vnode.nodeId,
                    name: attributeName,
                    value: newValue || '',
                },
            });
        }
    }
    function onCharacterDataChange(mutation) {
        var $target = mutation.target;
        elementStore_1.default.generateElementNode($target);
        var vnode = $target['__vnode__'];
        if (!vnode) {
            return;
        }
        var newValue = getTextContent_1.default($target);
        socket.fireEvent({
            method: "DOM.characterDataModified" /* characterDataModified */,
            params: {
                nodeId: vnode.nodeId,
                characterData: newValue,
            },
        });
    }
    var observer = new MutationObserver(function (mutations) {
        for (var i = 0; i < mutations.length; i++) {
            var mutation = mutations[i];
            onMutation(mutation);
        }
    });
    observer.observe(handlerContext.observerElement, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
        characterDataOldValue: true,
        attributeOldValue: true,
    });
});
