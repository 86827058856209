"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var collectClassNamesFromSubtree_1 = require("./collectClassNamesFromSubtree");
var copyTo_1 = require("./copyTo");
var enable_1 = require("./enable");
var getDocument_1 = require("./getDocument");
var getOuterHTML_1 = require("./getOuterHTML");
var markUndoableState_1 = require("./markUndoableState");
var redo_1 = require("./redo");
var removeNode_1 = require("./removeNode");
var requestChildNodes_1 = require("./requestChildNodes");
var resolveNode_1 = require("./resolveNode");
var setAttributesAsText_1 = require("./setAttributesAsText");
var setAttributeValue_1 = require("./setAttributeValue");
var setInspectedNode_1 = require("./setInspectedNode");
var setNodeValue_1 = require("./setNodeValue");
var setOuterHTML_1 = require("./setOuterHTML");
var undo_1 = require("./undo");
exports.default = {
    enable: enable_1.default,
    getDocument: getDocument_1.default,
    requestChildNodes: requestChildNodes_1.default,
    markUndoableState: markUndoableState_1.default,
    collectClassNamesFromSubtree: collectClassNamesFromSubtree_1.default,
    setNodeValue: setNodeValue_1.default,
    setAttributesAsText: setAttributesAsText_1.default,
    getOuterHTML: getOuterHTML_1.default,
    setOuterHTML: setOuterHTML_1.default,
    removeNode: removeNode_1.default,
    copyTo: copyTo_1.default,
    resolveNode: resolveNode_1.default,
    setAttributeValue: setAttributeValue_1.default,
    setInspectedNode: setInspectedNode_1.default,
    redo: redo_1.default,
    undo: undo_1.default,
};
