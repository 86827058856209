"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-empty */
var config_1 = require("../../common/config");
var framework_monitor_1 = require("../../utils/monitor/framework-monitor");
var objectManager_1 = require("../../utils/objectManager");
var url_1 = require("../../utils/url");
var monitor = framework_monitor_1.default(config_1.default.yuyanId);
var isDebug = url_1.getSearchParam(config_1.default.debugSymbol);
/**
 * overwrite console
 */
exports.default = (function (socket, handlerContext) {
    var consoleMethods = Object.keys(Object.getPrototypeOf(console));
    /**
     * try different way to grab console methods
     * (more supported by newer browser)
     */
    if (consoleMethods.length === 0) {
        consoleMethods = Object.getOwnPropertyNames(console);
    }
    var properties = [
        'debug',
        'log',
        'error',
        'table',
        'warn',
        'dir',
        'dirxml',
        'trace',
        'clear',
        'assert',
        'profile',
        'profileEnd',
        'count',
        'timeEnd',
    ];
    if (!isDebug) {
        properties.push('info');
    }
    Object.defineProperties(console, properties.reduce(function (props, method) {
        // @ts-ignore
        var _consoleMethod = console[method].bind(console);
        props[method] = {
            value: function MyError() {
                var args = arguments;
                if (!args.length) {
                    return;
                }
                try {
                    // 过滤掉部分可以造成循环调用的日志
                    var ignorePatter = /^(\[framework\]|dispatchEvent|onMessage push|unsupported protocol)/;
                    if (typeof args[0] === 'string' && ignorePatter.test(args[0])) {
                        return _consoleMethod.apply(null, args);
                    }
                    var type = method;
                    if (method === 'warn') {
                        type = 'warning';
                    }
                    var err = new Error();
                    if (err.stack) {
                        socket.fireEvent({
                            method: "Runtime.consoleAPICalled" /* consoleAPICalled */,
                            params: {
                                type: type,
                                args: handlerContext.objectMapper.mapFrom(args, {
                                    consoleMethod: method,
                                }),
                                executionContextId: objectManager_1.executionContextId,
                                timestamp: Date.now(),
                                stackTrace: {
                                    // @ts-ignore
                                    callFrames: handlerContext.callFramesMapper.mapFrom(err.stack),
                                },
                            },
                        });
                    }
                }
                catch (e) {
                    monitor.logJSError(e);
                }
            },
        };
        return props;
    }, {}));
});
