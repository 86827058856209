"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var css_1 = require("../../../utils/css");
exports.default = (function (msg, socket) {
    var id = msg.id, params = msg.params;
    var styleSheetId = params.styleSheetId;
    var returnText = '';
    var target = css_1.getParsedStyleSheetById(styleSheetId);
    if (target) {
        returnText = target.cssText;
    }
    socket.response({
        id: id,
        result: {
            text: returnText,
        },
    });
});
