"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg, socket) {
    var _a;
    var id = msg.id, params = msg.params;
    var nodeId = params.nodeId;
    socket.response({
        id: id,
        result: undefined,
    });
    var node = elementStore_1.default.get(nodeId);
    socket.fireEvent({
        method: "DOM.setChildNodes" /* setChildNodes */,
        params: {
            parentId: nodeId,
            nodes: ((_a = node.node) === null || _a === void 0 ? void 0 : _a.children) || [],
        },
    });
});
