"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyObject = exports.SUB_TYPES = void 0;
var objectStore_1 = require("./objectStore");
exports.SUB_TYPES = [
    'array', 'null', 'node', 'regexp', 'date', 'map', 'set', 'iterator',
    'generator', 'error', 'promise', 'typedarray',
];
/**
 * Facade for subtype property
 */
var PropertyObject = /** @class */ (function () {
    function PropertyObject(object) {
        var subtype = PropertyObject.getSubType(object);
        var type = typeof object;
        if (type.match(/^(number|string|undefined|boolean)$/) || subtype === 'null') {
            return new PrimitiveObject(object, subtype);
        }
        return PropertyObject.createPropertyInstance(object, subtype);
    }
    PropertyObject.createPropertyInstance = function (object, subtype) {
        if (subtype === 'array')
            return new ArrayObject(object, subtype);
        if (subtype === 'null')
            return new PrimitiveObject(object, subtype);
        if (subtype === 'undefined')
            return new PrimitiveObject(object, subtype);
        if (subtype === 'node')
            return new NodeObject(object, subtype);
        if (subtype === 'regexp')
            return new CompositeObject(object, subtype);
        if (subtype === 'date')
            return new CompositeObject(object, subtype);
        if (subtype === 'map')
            return new CompositeObject(object, subtype);
        if (subtype === 'set')
            return new CompositeObject(object, subtype);
        if (subtype === 'iterator')
            return new CompositeObject(object, subtype);
        if (subtype === 'generator')
            return new CompositeObject(object, subtype);
        if (subtype === 'error')
            return new ErrorObject(object, subtype);
        if (subtype === 'promise')
            return new PromiseObject(object, subtype);
        if (subtype === 'typedarray')
            return new TypedarrayObject(object, subtype);
        return new CompositeObject(object);
    };
    /**
       * returns subtype of object
       */
    PropertyObject.getSubType = function (object) {
        /**
             * null
             */
        if (object === null) {
            return 'null';
        }
        /**
             * undefined
             */
        if (typeof object === 'undefined') {
            return 'undefined';
        }
        /**
             * objects can have cases where constructor is null
             */
        if (!object.constructor) {
            return 'map';
        }
        var constructorName = object.constructor.name;
        /**
             * error
             */
        if (object instanceof Error || constructorName.match(/Error$/)) {
            return 'error';
        }
        /**
             * node
             */
        if (typeof object.nodeType === 'number') {
            return 'node';
        }
        /**
             * iterator
             */
        if (object.iterator) {
            return 'iterator';
        }
        /**
             * generator
             */
        if (constructorName === 'GeneratorFunction') {
            return 'generator';
        }
        /**
             * promise
             */
        if (object instanceof Promise) {
            return 'promise';
        }
        /**
             * array
             */
        if (Array.isArray(object) || (typeof object.length === 'number' && object.constructor.name !== 'object')) {
            return 'array';
        }
        /**
             * typedarray
             */
        if (constructorName.match(/^Float(\d+)Array$/)) {
            return 'typedarray';
        }
        /**
             * constructorName check
             */
        if (exports.SUB_TYPES.indexOf(constructorName.toLowerCase()) > -1) {
            return constructorName.toLowerCase;
        }
    };
    return PropertyObject;
}());
exports.PropertyObject = PropertyObject;
var PrimitiveObject = /** @class */ (function () {
    function PrimitiveObject(object, subtype) {
        this.isPrimitive = true;
        this.object = object;
        this.subtype = subtype || this.subtype;
        this.type = typeof object;
        this.value = this.object;
        this.className = this.object ? this.object.constructor.name : undefined;
    }
    PrimitiveObject.prototype.get = function () {
        var _a = this, value = _a.value, subtype = _a.subtype, type = _a.type, description = _a.description;
        return { value: value, subtype: subtype, type: type, description: description };
    };
    Object.defineProperty(PrimitiveObject.prototype, "description", {
        /**
           * for primitives the origin is the actual value except for 'null' and 'undefined'
           */
        get: function () {
            return this.object ? this.value.toString() : this.subtype;
        },
        enumerable: false,
        configurable: true
    });
    return PrimitiveObject;
}());
var CompositeObject = /** @class */ (function (_super) {
    __extends(CompositeObject, _super);
    function CompositeObject(object, subtype) {
        var _this = _super.call(this, object, subtype) || this;
        _this.isPrimitive = false;
        var objectId = objectStore_1.default.save(_this.object);
        _this.objectId = objectId;
        return _this;
    }
    // @ts-ignore
    CompositeObject.prototype.get = function () {
        var _a = this, className = _a.className, description = _a.description, objectId = _a.objectId, subtype = _a.subtype, type = _a.type;
        return { className: className, description: description, objectId: objectId, subtype: subtype, type: type };
    };
    Object.defineProperty(CompositeObject.prototype, "description", {
        get: function () {
            return this.object.constructor.name || this.object.toString();
        },
        enumerable: false,
        configurable: true
    });
    return CompositeObject;
}(PrimitiveObject));
var ArrayObject = /** @class */ (function (_super) {
    __extends(ArrayObject, _super);
    function ArrayObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ArrayObject.prototype, "description", {
        get: function () {
            return this.className + "(" + this.object.length + ")";
        },
        enumerable: false,
        configurable: true
    });
    return ArrayObject;
}(CompositeObject));
var NodeObject = /** @class */ (function (_super) {
    __extends(NodeObject, _super);
    function NodeObject(object, subtype) {
        var _this = _super.call(this, object, subtype) || this;
        _this.value = _this.getValue();
        _this.className = _this.object.constructor.name;
        return _this;
    }
    Object.defineProperty(NodeObject.prototype, "description", {
        get: function () {
            return this.object.nodeName.toLowerCase();
        },
        enumerable: false,
        configurable: true
    });
    NodeObject.prototype.getValue = function () {
        var value = this.object.nodeName.toLowerCase();
        if (this.object.id) {
            value += "#" + this.object.id;
        }
        if (this.object.className) {
            value += "." + this.object.className.replace(' ', '.');
        }
        return value;
    };
    return NodeObject;
}(CompositeObject));
var ErrorObject = /** @class */ (function (_super) {
    __extends(ErrorObject, _super);
    function ErrorObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.className = 'Error';
        return _this;
    }
    Object.defineProperty(ErrorObject.prototype, "description", {
        get: function () {
            return this.object.stack;
        },
        enumerable: false,
        configurable: true
    });
    return ErrorObject;
}(CompositeObject));
var PromiseObject = /** @class */ (function (_super) {
    __extends(PromiseObject, _super);
    function PromiseObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.className = 'Promise';
        // @ts-ignore
        _this.description = 'Promise';
        return _this;
    }
    return PromiseObject;
}(CompositeObject));
var TypedarrayObject = /** @class */ (function (_super) {
    __extends(TypedarrayObject, _super);
    function TypedarrayObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.className = 'TypedarrayObject';
        // @ts-ignore
        _this.description = 'TypedarrayObject';
        return _this;
    }
    return TypedarrayObject;
}(CompositeObject));
