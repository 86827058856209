"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrameworkMonitor = exports.EMonitorCode = void 0;
var lodash_1 = require("lodash");
var config_1 = require("../../common/config");
var logger_1 = require("../logger");
var url_1 = require("../url");
var yuyan_monitor_1 = require("./yuyan-monitor");
var EApiName;
(function (EApiName) {
    EApiName["GET_EXTERNAL_STYLE_SHEET"] = "fc.remoteDebugSdkMiddleware.getExternalStyleSheet";
})(EApiName || (EApiName = {}));
var EMonitorCode;
(function (EMonitorCode) {
    EMonitorCode[EMonitorCode["JS_ERROR"] = 15] = "JS_ERROR";
    EMonitorCode[EMonitorCode["API_ERROR"] = 18] = "API_ERROR";
    EMonitorCode[EMonitorCode["PV"] = 16] = "PV";
    EMonitorCode[EMonitorCode["SPLASH_SCREEN"] = 17] = "SPLASH_SCREEN";
    EMonitorCode[EMonitorCode["API_INVOKE"] = 19] = "API_INVOKE";
})(EMonitorCode = exports.EMonitorCode || (exports.EMonitorCode = {}));
/**
 * Get default env for monitor.
 */
function getDefaultEnv() {
    return 'prod';
}
var FrameworkMonitor = /** @class */ (function () {
    function FrameworkMonitor(opts) {
        this.opts = opts;
        this.Tracert = new yuyan_monitor_1.default({
            yuyanId: opts.yuyanId,
            _appId: opts._appId,
            env: opts.env || getDefaultEnv(),
            userId: this.username,
        });
        this.pendingLogItems = [];
        this.commonParams = {};
    }
    FrameworkMonitor.prototype.setParams = function (params) {
        this.commonParams = params;
    };
    Object.defineProperty(FrameworkMonitor.prototype, "username", {
        get: function () {
            return (document.URL + " - " + window.navigator.userAgent).replace(/,/g, '%2C');
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 环境变量 NO_REPORT 控制是否要进行数据上报
     *
     * @param logItem
     * @param immediately
     * @returns
     */
    FrameworkMonitor.prototype.log = function (logItem, immediately) {
        if (immediately === void 0) { immediately = false; }
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!immediately) return [3 /*break*/, 5];
                        if (this.opts.debug) {
                            logger_1.debug('report', logItem);
                        }
                        if (this.opts.noReport)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.Tracert.log(logItem)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5: return [2 /*return*/, this.pendingLogItems.push(logItem)];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    FrameworkMonitor.prototype.flush = function () {
        return __awaiter(this, void 0, void 0, function () {
            var logItem, normalizedLogItem;
            var _this = this;
            return __generator(this, function (_a) {
                while (this.pendingLogItems.length) {
                    logItem = this.pendingLogItems.shift();
                    normalizedLogItem = typeof logItem === 'function'
                        ? logItem()
                        : logItem;
                    if (Array.isArray(normalizedLogItem)) {
                        normalizedLogItem.forEach(function (item) { return _this.log(item, true); });
                    }
                    else if (lodash_1.isPlainObject(normalizedLogItem)) {
                        normalizedLogItem && this.log(normalizedLogItem, true);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    FrameworkMonitor.prototype.lazyFlush = function () {
        var _this = this;
        setTimeout(function () {
            _this.flush();
        });
    };
    /**
     * 上报cli pv
     * msg: 当前sdk版本
     * d1: 页面链接+ua，唯一标识一个用户
     * c1: tyroId
     */
    FrameworkMonitor.prototype.logPv = function () {
        var _this = this;
        this.pendingLogItems.push(function () { return ({
            code: EMonitorCode.PV,
            msg: config_1.default.sdkVersion || '',
            d1: _this.username,
            c1: _this.commonParams.tyroId,
        }); });
        this.lazyFlush();
        return this;
    };
    /**
     * 上报jserror错误信息和堆栈
     * msg: error.message
     * d1: 当前sdk版本
     * d2: 页面链接+ua
     * c1: tyroId
     * c2: error.stack
     * @param err
     */
    FrameworkMonitor.prototype.logJSError = function (err) {
        var _this = this;
        this.pendingLogItems.push(function () { return ({
            code: EMonitorCode.JS_ERROR,
            msg: err.message,
            d1: config_1.default.sdkVersion,
            d2: _this.username,
            c1: _this.commonParams.tyroId,
            c2: err.stack,
        }); });
        this.lazyFlush();
        return this;
    };
    /**
     * 上报首屏耗时
     * msg: 当前sdk版本
     * d1: 页面链接+ua
     * m1: duration 最大耗时
     * m2: duration 平均耗时
     * m3: duration 最小耗时
     * c1: tyroId
     *
     * @param duration 首屏耗时
     */
    FrameworkMonitor.prototype.logSplashScreen = function () {
        var _this = this;
        var timing = window.performance.timing;
        var domLoadedTime = timing.domContentLoadedEventStart - timing.navigationStart;
        this.pendingLogItems.push(function () { return ({
            code: EMonitorCode.SPLASH_SCREEN,
            msg: config_1.default.sdkVersion || '',
            d1: _this.username,
            m1: domLoadedTime,
            m2: domLoadedTime,
            m3: domLoadedTime,
            c1: _this.commonParams.tyroId,
        }); });
        this.lazyFlush();
        return this;
    };
    /**
     * 上报接口报错
     * msg: error.message
     * d1: 当前sdk版本
     * d2: 页面链接+ua
     * d3: apiName
     * c1: tyroId
     * c2: api 入参
     * c3: api 出参
     *
     * @param apiName 接口名
     * @param errMsg 接口报错信息
     * @param params 接口入参
     * @param body 接口出参
     */
    FrameworkMonitor.prototype.logApiError = function (apiName, errMsg, params, body) {
        var _this = this;
        this.pendingLogItems.push(function () { return ({
            code: EMonitorCode.API_ERROR,
            msg: errMsg,
            d1: config_1.default.sdkVersion,
            d2: _this.username,
            d3: apiName,
            c1: _this.commonParams.tyroId,
            c2: JSON.stringify(params),
            c3: body,
        }); });
        this.lazyFlush();
        return this;
    };
    /**
     * 上报接口调用次数
     * msg: apiName
     * d1: 当前sdk版本
     * d2: 页面链接+ua
     * c1: tyroId
     * @param apiName
     */
    FrameworkMonitor.prototype.logApiInvoke = function (apiName) {
        var _this = this;
        this.pendingLogItems.push(function () { return ({
            code: EMonitorCode.API_INVOKE,
            msg: apiName,
            d1: config_1.default.sdkVersion,
            d2: _this.username,
            c1: _this.commonParams.tyroId,
        }); });
        this.lazyFlush();
        return this;
    };
    return FrameworkMonitor;
}());
exports.FrameworkMonitor = FrameworkMonitor;
var monitorMap = {};
var getMonitor = function (yuyanId) {
    if (monitorMap[yuyanId])
        return monitorMap[yuyanId];
    return new FrameworkMonitor({
        yuyanId: yuyanId,
        debug: !!url_1.getSearchParam(config_1.default.debugSymbol),
        noReport: !!url_1.getSearchParam(config_1.default.noReportSymbol),
    });
};
exports.default = getMonitor;
