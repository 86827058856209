"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (node) {
    var computedStyle = window.getComputedStyle(node);
    var mustPositive = function (px) { return (Number(px.slice(0, -2)) < 0 ? '0px' : px); };
    return {
        border: {
            bottom: computedStyle.borderBottomWidth,
            left: computedStyle.borderLeftWidth,
            right: computedStyle.borderRightWidth,
            top: computedStyle.borderTopWidth,
        },
        boxSizing: computedStyle.boxSizing,
        margin: {
            bottom: mustPositive(computedStyle.marginBottom),
            left: mustPositive(computedStyle.marginLeft),
            right: mustPositive(computedStyle.marginRight),
            top: mustPositive(computedStyle.marginTop),
        },
        padding: {
            bottom: computedStyle.paddingBottom,
            left: computedStyle.paddingLeft,
            right: computedStyle.paddingRight,
            top: computedStyle.paddingTop,
        },
    };
});
