"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg) {
    var params = msg.params;
    var nodeId = params.nodeId, text = params.text; // text 为当前编辑框内提交的属性, name 为选择编辑的属性，但 text 内可以包含新增的属性
    var element = elementStore_1.default.get(nodeId).element;
    var parser = new DOMParser();
    var doc = parser.parseFromString("<div " + text + "></div>", 'text/html');
    var fakeElement = doc.querySelectorAll('div')[0];
    var attributesMap = fakeElement.attributes;
    for (var _i = 0, _a = Array.from(attributesMap); _i < _a.length; _i++) {
        var attribute = _a[_i];
        var value = fakeElement.getAttribute(attribute.name);
        element.setAttribute(attribute.name, value || '');
    }
});
