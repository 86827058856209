"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (obj) {
    var name = Object.prototype.toString.call(obj);
    if (name) {
        return name.slice(8, name.length - 1);
    }
    else {
        return '';
    }
});
