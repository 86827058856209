"use strict";
/* eslint-disable @typescript-eslint/member-ordering */
/*
   * Copyright (C) 2012 Google Inc. All rights reserved.
   *
   * Redistribution and use in source and binary forms, with or without
   * modification, are permitted provided that the following conditions are
   * met:
   *
   *     * Redistributions of source code must retain the above copyright
   * notice, this list of conditions and the following disclaimer.
   *     * Redistributions in binary form must reproduce the above
   * copyright notice, this list of conditions and the following disclaimer
   * in the documentation and/or other materials provided with the
   * distribution.
   *     * Neither the name of Google Inc. nor the names of its
   * contributors may be used to endorse or promote products derived from
   * this software without specific prior written permission.
   *
   * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
   * "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
   * LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
   * A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT
   * OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
   * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
   * LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
   * DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
   * THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
   * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
   * OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
   */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceType = void 0;
/**
   * @unrestricted
   */
var ParsedURL = /** @class */ (function () {
    /**
       * @param {string} url
       */
    function ParsedURL(url) {
        this.isValid = false;
        this.url = url;
        this.scheme = '';
        this.user = '';
        this.host = '';
        this.port = '';
        this.path = '';
        this.queryParams = '';
        this.fragment = '';
        this.folderPathComponents = '';
        this.lastPathComponent = '';
        var isBlobUrl = this.url.startsWith('blob:');
        var urlToMatch = isBlobUrl ? url.substring(5) : url;
        var match = urlToMatch.match(ParsedURL._urlRegex());
        if (match) {
            this.isValid = true;
            if (isBlobUrl) {
                this._blobInnerScheme = match[2].toLowerCase();
                this.scheme = 'blob';
            }
            else {
                this.scheme = match[2].toLowerCase();
            }
            this.user = match[3];
            this.host = match[4];
            this.port = match[5];
            this.path = match[6] || '/';
            this.queryParams = match[7] || '';
            this.fragment = match[8];
        }
        else {
            if (this.url.startsWith('data:')) {
                this.scheme = 'data';
                return;
            }
            if (this.url.startsWith('blob:')) {
                this.scheme = 'blob';
                return;
            }
            if (this.url === 'about:blank') {
                this.scheme = 'about';
                return;
            }
            this.path = this.url;
        }
        var lastSlashIndex = this.path.lastIndexOf('/');
        if (lastSlashIndex !== -1) {
            this.folderPathComponents = this.path.substring(0, lastSlashIndex);
            this.lastPathComponent = this.path.substring(lastSlashIndex + 1);
        }
        else {
            this.lastPathComponent = this.path;
        }
    }
    /**
       * @param {string} string
       * @return {?ParsedURL}
       */
    ParsedURL.fromString = function (string) {
        var parsedURL = new ParsedURL(string.toString());
        if (parsedURL.isValid) {
            return parsedURL;
        }
        return null;
    };
    /**
       * @param {string} fileSystemPath
       * @return {string}
       */
    ParsedURL.platformPathToURL = function (fileSystemPath) {
        fileSystemPath = fileSystemPath.replace(/\\/g, '/');
        if (!fileSystemPath.startsWith('file://')) {
            if (fileSystemPath.startsWith('/')) {
                fileSystemPath = "file://" + fileSystemPath;
            }
            else {
                fileSystemPath = "file:///" + fileSystemPath;
            }
        }
        return fileSystemPath;
    };
    /**
       * @param {string} fileURL
       * @param {boolean=} isWindows
       * @return {string}
       */
    ParsedURL.urlToPlatformPath = function (fileURL, isWindows) {
        console.assert(fileURL.startsWith('file://'), 'This must be a file URL.');
        if (isWindows) {
            return fileURL.substr('file:///'.length).replace(/\//g, '\\');
        }
        return fileURL.substr('file://'.length);
    };
    /**
       * @param {string} url
       * @return {string}
       */
    ParsedURL.urlWithoutHash = function (url) {
        var hashIndex = url.indexOf('#');
        if (hashIndex !== -1) {
            return url.substr(0, hashIndex);
        }
        return url;
    };
    /**
       * @return {!RegExp}
       */
    ParsedURL._urlRegex = function () {
        if (ParsedURL._urlRegexInstance) {
            return ParsedURL._urlRegexInstance;
        }
        // RegExp groups:
        // 1 - scheme, hostname, ?port
        // 2 - scheme (using the RFC3986 grammar)
        // 3 - ?user:password
        // 4 - hostname
        // 5 - ?port
        // 6 - ?path
        // 7 - ?query
        // 8 - ?fragment
        var schemeRegex = /([A-Za-z][A-Za-z0-9+.-]*):\/\//;
        var userRegex = /(?:([A-Za-z0-9\-._~%!$&'()*+,;=:]*)@)?/;
        var hostRegex = /((?:\[::\d?\])|(?:[^\s/:]*))/;
        var portRegex = /(?::([\d]+))?/;
        var pathRegex = /(\/[^#?]*)?/;
        var queryRegex = /(?:\?([^#]*))?/;
        var fragmentRegex = /(?:#(.*))?/;
        ParsedURL._urlRegexInstance = new RegExp("^(" + schemeRegex.source + userRegex.source + hostRegex.source + portRegex.source + ")" + pathRegex.source + queryRegex.source + fragmentRegex.source + "$");
        return ParsedURL._urlRegexInstance;
    };
    /**
       * @param {string} url
       * @return {string}
       */
    ParsedURL.extractPath = function (url) {
        var parsedURL = this.fromString(url);
        return parsedURL ? parsedURL.path : '';
    };
    /**
       * @param {string} url
       * @return {string}
       */
    ParsedURL.extractOrigin = function (url) {
        var parsedURL = this.fromString(url);
        return parsedURL ? parsedURL.securityOrigin() : '';
    };
    /**
       * @param {string} url
       * @return {string}
       */
    ParsedURL.extractExtension = function (url) {
        url = ParsedURL.urlWithoutHash(url);
        var indexOfQuestionMark = url.indexOf('?');
        if (indexOfQuestionMark !== -1) {
            url = url.substr(0, indexOfQuestionMark);
        }
        var lastIndexOfSlash = url.lastIndexOf('/');
        if (lastIndexOfSlash !== -1) {
            url = url.substr(lastIndexOfSlash + 1);
        }
        var lastIndexOfDot = url.lastIndexOf('.');
        if (lastIndexOfDot !== -1) {
            url = url.substr(lastIndexOfDot + 1);
            var lastIndexOfPercent = url.indexOf('%');
            if (lastIndexOfPercent !== -1) {
                return url.substr(0, lastIndexOfPercent);
            }
            return url;
        }
        return '';
    };
    /**
       * @param {string} url
       * @return {string}
       */
    ParsedURL.extractName = function (url) {
        var index = url.lastIndexOf('/');
        var pathAndQuery = index !== -1 ? url.substr(index + 1) : url;
        index = pathAndQuery.indexOf('?');
        return index < 0 ? pathAndQuery : pathAndQuery.substr(0, index);
    };
    /**
       * @param {string} baseURL
       * @param {string} href
       * @return {?string}
       */
    ParsedURL.completeURL = function (baseURL, href) {
        // Return special URLs as-is.
        var trimmedHref = href.trim();
        // eslint-disable-next-line no-script-url
        if (trimmedHref.startsWith('data:') || trimmedHref.startsWith('blob:') || trimmedHref.startsWith('javascript:') ||
            trimmedHref.startsWith('mailto:')) {
            return href;
        }
        // Return absolute URLs as-is.
        var parsedHref = this.fromString(trimmedHref);
        if (parsedHref && parsedHref.scheme) {
            return trimmedHref;
        }
        var parsedURL = this.fromString(baseURL);
        if (!parsedURL) {
            return null;
        }
        if (parsedURL.isDataURL()) {
            return href;
        }
        if (href.length > 1 && href.charAt(0) === '/' && href.charAt(1) === '/') {
            // href starts with "//" which is a full URL with the protocol dropped (use the baseURL protocol).
            return parsedURL.scheme + ":" + href;
        }
        var securityOrigin = parsedURL.securityOrigin();
        var pathText = parsedURL.path;
        var queryText = parsedURL.queryParams ? "?" + parsedURL.queryParams : '';
        // Empty href resolves to a URL without fragment.
        if (!href.length) {
            return securityOrigin + pathText + queryText;
        }
        if (href.charAt(0) === '#') {
            return securityOrigin + pathText + queryText + href;
        }
        if (href.charAt(0) === '?') {
            return securityOrigin + pathText + href;
        }
        var hrefMatches = href.match(/^[^#?]*/);
        if (!hrefMatches || !href.length) {
            throw new Error('Invalid href');
        }
        var hrefPath = hrefMatches[0];
        var hrefSuffix = href.substring(hrefPath.length);
        if (hrefPath.charAt(0) !== '/') {
            hrefPath = parsedURL.folderPathComponents + "/" + hrefPath;
        }
        // @ts-ignore Runtime needs to be properly exported
        return securityOrigin + Root.Runtime.normalizePath(hrefPath) + hrefSuffix;
    };
    /**
       * @param {string} string
       * @return {!{url: string, lineNumber: (number|undefined), columnNumber: (number|undefined)}}
       */
    ParsedURL.splitLineAndColumn = function (string) {
        // Only look for line and column numbers in the path to avoid matching port numbers.
        var beforePathMatch = string.match(ParsedURL._urlRegex());
        var beforePath = '';
        var pathAndAfter = string;
        if (beforePathMatch) {
            beforePath = beforePathMatch[1];
            pathAndAfter = string.substring(beforePathMatch[1].length);
        }
        var lineColumnRegEx = /(?::(\d+))?(?::(\d+))?$/;
        var lineColumnMatch = lineColumnRegEx.exec(pathAndAfter);
        var lineNumber;
        var columnNumber;
        console.assert(!!lineColumnMatch);
        if (!lineColumnMatch) {
            return { url: string, lineNumber: 0, columnNumber: 0 };
        }
        if (typeof (lineColumnMatch[1]) === 'string') {
            lineNumber = parseInt(lineColumnMatch[1], 10);
            // Immediately convert line and column to 0-based numbers.
            lineNumber = isNaN(lineNumber) ? undefined : lineNumber - 1;
        }
        if (typeof (lineColumnMatch[2]) === 'string') {
            columnNumber = parseInt(lineColumnMatch[2], 10);
            columnNumber = isNaN(columnNumber) ? undefined : columnNumber - 1;
        }
        return {
            url: beforePath + pathAndAfter.substring(0, pathAndAfter.length - lineColumnMatch[0].length),
            lineNumber: lineNumber,
            columnNumber: columnNumber,
        };
    };
    /**
       * @param {string} url
       * @return {string}
       */
    ParsedURL.removeWasmFunctionInfoFromURL = function (url) {
        var wasmFunctionRegEx = /:wasm-function\[\d+\]/;
        var wasmFunctionIndex = url.search(wasmFunctionRegEx);
        if (wasmFunctionIndex === -1) {
            return url;
        }
        return url.substring(0, wasmFunctionIndex);
    };
    /**
       * @param {string} url
       * @return {boolean}
       */
    ParsedURL.isRelativeURL = function (url) {
        return !(/^[A-Za-z][A-Za-z0-9+.-]*:/.test(url));
    };
    Object.defineProperty(ParsedURL.prototype, "displayName", {
        get: function () {
            if (this._displayName) {
                return this._displayName;
            }
            if (this.isDataURL()) {
                return this.dataURLDisplayName();
            }
            if (this.isBlobURL()) {
                return this.url;
            }
            if (this.isAboutBlank()) {
                return this.url;
            }
            this._displayName = this.lastPathComponent;
            if (!this._displayName) {
                this._displayName = (this.host || '') + "/";
            }
            if (this._displayName === '/') {
                this._displayName = this.url;
            }
            return this._displayName;
        },
        enumerable: false,
        configurable: true
    });
    /**
       * @return {string}
       */
    ParsedURL.prototype.dataURLDisplayName = function () {
        if (this._dataURLDisplayName) {
            return this._dataURLDisplayName;
        }
        if (!this.isDataURL()) {
            return '';
        }
        this._dataURLDisplayName = this.url.trimEndWithMaxLength(20);
        return this._dataURLDisplayName;
    };
    /**
       * @return {boolean}
       */
    ParsedURL.prototype.isAboutBlank = function () {
        return this.url === 'about:blank';
    };
    /**
       * @return {boolean}
       */
    ParsedURL.prototype.isDataURL = function () {
        return this.scheme === 'data';
    };
    /**
       * @return {boolean}
       */
    ParsedURL.prototype.isBlobURL = function () {
        return this.url.startsWith('blob:');
    };
    /**
       * @return {string}
       */
    ParsedURL.prototype.lastPathComponentWithFragment = function () {
        return this.lastPathComponent + (this.fragment ? "#" + this.fragment : '');
    };
    /**
       * @return {string}
       */
    ParsedURL.prototype.domain = function () {
        if (this.isDataURL()) {
            return 'data:';
        }
        return this.host + (this.port ? ":" + this.port : '');
    };
    /**
       * @return {string}
       */
    ParsedURL.prototype.securityOrigin = function () {
        if (this.isDataURL()) {
            return 'data:';
        }
        var scheme = this.isBlobURL() ? this._blobInnerScheme : this.scheme;
        return scheme + "://" + this.domain();
    };
    /**
       * @return {string}
       */
    ParsedURL.prototype.urlWithoutScheme = function () {
        if (this.scheme && this.url.startsWith(this.scheme + "://")) {
            return this.url.substring(this.scheme.length + 3);
        }
        return this.url;
    };
    return ParsedURL;
}());
/** @type {?RegExp} */
ParsedURL._urlRegexInstance = null;
/*
   * Copyright (C) 2019 Google Inc. All rights reserved.
   *
   * Redistribution and use in source and binary forms, with or without
   * modification, are permitted provided that the following conditions are
   * met:
   *
   *     * Redistributions of source code must retain the above copyright
   * notice, this list of conditions and the following disclaimer.
   *     * Redistributions in binary form must reproduce the above
   * copyright notice, this list of conditions and the following disclaimer
   * in the documentation and/or other materials provided with the
   * distribution.
   *     * Neither the name of Google Inc. nor the names of its
   * contributors may be used to endorse or promote products derived from
   * this software without specific prior written permission.
   *
   * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
   * "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
   * LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
   * A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT
   * OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
   * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
   * LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
   * DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
   * THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
   * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
   * OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
   */
var ls = function (x) { return x; };
/*
   * Copyright (C) 2012 Google Inc.  All rights reserved.
   * Copyright (C) 2007, 2008 Apple Inc.  All rights reserved.
   *
   * Redistribution and use in source and binary forms, with or without
   * modification, are permitted provided that the following conditions
   * are met:
   *
   * 1.  Redistributions of source code must retain the above copyright
   *     notice, this list of conditions and the following disclaimer.
   * 2.  Redistributions in binary form must reproduce the above copyright
   *     notice, this list of conditions and the following disclaimer in the
   *     documentation and/or other materials provided with the distribution.
   * 3.  Neither the name of Apple Computer, Inc. ("Apple") nor the names of
   *     its contributors may be used to endorse or promote products derived
   *     from this software without specific prior written permission.
   *
   * THIS SOFTWARE IS PROVIDED BY APPLE AND ITS CONTRIBUTORS "AS IS" AND ANY
   * EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
   * WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE
   * DISCLAIMED. IN NO EVENT SHALL APPLE OR ITS CONTRIBUTORS BE LIABLE FOR ANY
   * DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES
   * (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES;
   * LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND
   * ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
   * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF
   * THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
   */
/**
   * @unrestricted
   */
var ResourceType = /** @class */ (function () {
    /**
       * @param {string} name
       * @param {string} title
       * @param {!ResourceCategory} category
       * @param {boolean} isTextType
       */
    function ResourceType(name, title, category, isTextType) {
        this._name = name;
        this._title = title;
        this._category = category;
        this._isTextType = isTextType;
    }
    /**
       * @param {?string} mimeType
       * @return {!ResourceType}
       */
    ResourceType.fromMimeType = function (mimeType) {
        if (!mimeType) {
            return resourceTypes.Other;
        }
        if (mimeType.startsWith('text/html')) {
            return resourceTypes.Document;
        }
        if (mimeType.startsWith('text/css')) {
            return resourceTypes.Stylesheet;
        }
        if (mimeType.startsWith('image/')) {
            return resourceTypes.Image;
        }
        if (mimeType.startsWith('text/')) {
            return resourceTypes.Script;
        }
        if (mimeType.includes('font')) {
            return resourceTypes.Font;
        }
        if (mimeType.includes('script')) {
            return resourceTypes.Script;
        }
        if (mimeType.includes('octet')) {
            return resourceTypes.Other;
        }
        if (mimeType.includes('application')) {
            return resourceTypes.Script;
        }
        return resourceTypes.Other;
    };
    /**
       * @param {string} url
       * @return {?ResourceType}
       */
    ResourceType.fromURL = function (url) {
        return _resourceTypeByExtension.get(ParsedURL.extractExtension(url)) || null;
    };
    /**
       * @param {string} name
       * @return {?ResourceType}
       */
    ResourceType.fromName = function (name) {
        for (var resourceTypeId in resourceTypes) {
            var resourceType = /** @type {!Object<string, !ResourceType>} */ (resourceTypes)[resourceTypeId];
            if (resourceType.name() === name) {
                return resourceType;
            }
        }
        return null;
    };
    /**
       * @param {string} url
       * @return {string|undefined}
       */
    ResourceType.mimeFromURL = function (url) {
        var name = ParsedURL.extractName(url);
        if (_mimeTypeByName.has(name)) {
            return _mimeTypeByName.get(name);
        }
        var ext = ParsedURL.extractExtension(url).toLowerCase();
        return _mimeTypeByExtension.get(ext);
    };
    /**
       * @param {string} ext
       * @return {string|undefined}
       */
    ResourceType.mimeFromExtension = function (ext) {
        return _mimeTypeByExtension.get(ext);
    };
    /**
       * @return {string}
       */
    ResourceType.prototype.name = function () {
        return this._name;
    };
    /**
       * @return {string}
       */
    ResourceType.prototype.title = function () {
        return this._title;
    };
    /**
       * @return {!ResourceCategory}
       */
    ResourceType.prototype.category = function () {
        return this._category;
    };
    /**
       * @return {boolean}
       */
    ResourceType.prototype.isTextType = function () {
        return this._isTextType;
    };
    /**
       * @return {boolean}
       */
    ResourceType.prototype.isScript = function () {
        return this._name === 'script' || this._name === 'sm-script';
    };
    /**
       * @return {boolean}
       */
    ResourceType.prototype.hasScripts = function () {
        return this.isScript() || this.isDocument();
    };
    /**
       * @return {boolean}
       */
    ResourceType.prototype.isStyleSheet = function () {
        return this._name === 'stylesheet' || this._name === 'sm-stylesheet';
    };
    /**
       * @return {boolean}
       */
    ResourceType.prototype.isDocument = function () {
        return this._name === 'document';
    };
    /**
       * @return {boolean}
       */
    ResourceType.prototype.isDocumentOrScriptOrStyleSheet = function () {
        return this.isDocument() || this.isScript() || this.isStyleSheet();
    };
    /**
       * @return {boolean}
       */
    ResourceType.prototype.isFromSourceMap = function () {
        return this._name.startsWith('sm-');
    };
    /**
       * @override
       * @return {string}
       */
    ResourceType.prototype.toString = function () {
        return this._name;
    };
    /**
       * @return {string}
       */
    ResourceType.prototype.canonicalMimeType = function () {
        if (this.isDocument()) {
            return 'text/html';
        }
        if (this.isScript()) {
            return 'text/javascript';
        }
        if (this.isStyleSheet()) {
            return 'text/css';
        }
        return '';
    };
    return ResourceType;
}());
exports.ResourceType = ResourceType;
/**
   * @unrestricted
   */
var ResourceCategory = /** @class */ (function () {
    /**
     * @param {string} title
     * @param {string} shortTitle
     */
    function ResourceCategory(title, shortTitle) {
        this.title = title;
        this.shortTitle = shortTitle;
    }
    return ResourceCategory;
}());
/**
 * @enum {!ResourceCategory}
 */
var resourceCategories = {
    XHR: new ResourceCategory(ls(templateObject_1 || (templateObject_1 = __makeTemplateObject(["XHR and Fetch"], ["XHR and Fetch"]))), ls(templateObject_2 || (templateObject_2 = __makeTemplateObject(["XHR"], ["XHR"])))),
    Script: new ResourceCategory(ls(templateObject_3 || (templateObject_3 = __makeTemplateObject(["Scripts"], ["Scripts"]))), ls(templateObject_4 || (templateObject_4 = __makeTemplateObject(["JS"], ["JS"])))),
    Stylesheet: new ResourceCategory(ls(templateObject_5 || (templateObject_5 = __makeTemplateObject(["Stylesheets"], ["Stylesheets"]))), ls(templateObject_6 || (templateObject_6 = __makeTemplateObject(["CSS"], ["CSS"])))),
    Image: new ResourceCategory(ls(templateObject_7 || (templateObject_7 = __makeTemplateObject(["Images"], ["Images"]))), ls(templateObject_8 || (templateObject_8 = __makeTemplateObject(["Img"], ["Img"])))),
    Media: new ResourceCategory(ls(templateObject_9 || (templateObject_9 = __makeTemplateObject(["Media"], ["Media"]))), ls(templateObject_10 || (templateObject_10 = __makeTemplateObject(["Media"], ["Media"])))),
    Font: new ResourceCategory(ls(templateObject_11 || (templateObject_11 = __makeTemplateObject(["Fonts"], ["Fonts"]))), ls(templateObject_12 || (templateObject_12 = __makeTemplateObject(["Font"], ["Font"])))),
    Document: new ResourceCategory(ls(templateObject_13 || (templateObject_13 = __makeTemplateObject(["Documents"], ["Documents"]))), ls(templateObject_14 || (templateObject_14 = __makeTemplateObject(["Doc"], ["Doc"])))),
    WebSocket: new ResourceCategory(ls(templateObject_15 || (templateObject_15 = __makeTemplateObject(["WebSockets"], ["WebSockets"]))), ls(templateObject_16 || (templateObject_16 = __makeTemplateObject(["WS"], ["WS"])))),
    Manifest: new ResourceCategory(ls(templateObject_17 || (templateObject_17 = __makeTemplateObject(["Manifest"], ["Manifest"]))), ls(templateObject_18 || (templateObject_18 = __makeTemplateObject(["Manifest"], ["Manifest"])))),
    Other: new ResourceCategory(ls(templateObject_19 || (templateObject_19 = __makeTemplateObject(["Other"], ["Other"]))), ls(templateObject_20 || (templateObject_20 = __makeTemplateObject(["Other"], ["Other"])))),
};
/**
   * Keep these in sync with WebCore::InspectorPageAgent::resourceTypeJson
   * @enum {!ResourceType}
   */
var resourceTypes = {
    Document: new ResourceType('document', 'Document', resourceCategories.Document, true),
    Stylesheet: new ResourceType('stylesheet', 'Stylesheet', resourceCategories.Stylesheet, true),
    Image: new ResourceType('image', 'Image', resourceCategories.Image, false),
    Media: new ResourceType('media', 'Media', resourceCategories.Media, false),
    Font: new ResourceType('font', 'Font', resourceCategories.Font, false),
    Script: new ResourceType('script', 'Script', resourceCategories.Script, true),
    TextTrack: new ResourceType('texttrack', 'TextTrack', resourceCategories.Other, true),
    XHR: new ResourceType('xhr', 'XHR', resourceCategories.XHR, true),
    Fetch: new ResourceType('fetch', 'Fetch', resourceCategories.XHR, true),
    EventSource: new ResourceType('eventsource', 'EventSource', resourceCategories.XHR, true),
    WebSocket: new ResourceType('websocket', 'WebSocket', resourceCategories.WebSocket, false),
    Manifest: new ResourceType('manifest', 'Manifest', resourceCategories.Manifest, true),
    SignedExchange: new ResourceType('signed-exchange', 'SignedExchange', resourceCategories.Other, false),
    Ping: new ResourceType('ping', 'Ping', resourceCategories.Other, false),
    CSPViolationReport: new ResourceType('csp-violation-report', 'CSPViolationReport', resourceCategories.Other, false),
    Other: new ResourceType('other', 'Other', resourceCategories.Other, false),
    SourceMapScript: new ResourceType('sm-script', 'Script', resourceCategories.Script, true),
    SourceMapStyleSheet: new ResourceType('sm-stylesheet', 'Stylesheet', resourceCategories.Stylesheet, true),
};
var _mimeTypeByName = new Map([
    // CoffeeScript
    ['Cakefile', 'text/x-coffeescript'],
]);
var _resourceTypeByExtension = new Map([
    ['js', resourceTypes.Script], ['mjs', resourceTypes.Script],
    ['css', resourceTypes.Stylesheet], ['xsl', resourceTypes.Stylesheet],
    ['jpeg', resourceTypes.Image], ['jpg', resourceTypes.Image], ['svg', resourceTypes.Image],
    ['gif', resourceTypes.Image], ['png', resourceTypes.Image], ['ico', resourceTypes.Image],
    ['tiff', resourceTypes.Image], ['tif', resourceTypes.Image], ['bmp', resourceTypes.Image],
    ['webp', resourceTypes.Media],
    ['ttf', resourceTypes.Font], ['otf', resourceTypes.Font], ['ttc', resourceTypes.Font], ['woff', resourceTypes.Font],
]);
var _mimeTypeByExtension = new Map([
    // Web extensions
    ['js', 'text/javascript'], ['mjs', 'text/javascript'], ['css', 'text/css'], ['html', 'text/html'],
    ['htm', 'text/html'], ['xml', 'application/xml'], ['xsl', 'application/xml'],
    // HTML Embedded Scripts, ASP], JSP
    ['asp', 'application/x-aspx'], ['aspx', 'application/x-aspx'], ['jsp', 'application/x-jsp'],
    // C/C++
    ['c', 'text/x-c++src'], ['cc', 'text/x-c++src'], ['cpp', 'text/x-c++src'], ['h', 'text/x-c++src'],
    ['m', 'text/x-c++src'], ['mm', 'text/x-c++src'],
    // CoffeeScript
    ['coffee', 'text/x-coffeescript'],
    // Dart
    ['dart', 'text/javascript'],
    // TypeScript
    ['ts', 'text/typescript'], ['tsx', 'text/typescript-jsx'],
    // JSON
    ['json', 'application/json'], ['gyp', 'application/json'], ['gypi', 'application/json'],
    // C#
    ['cs', 'text/x-csharp'],
    // Java
    ['java', 'text/x-java'],
    // Less
    ['less', 'text/x-less'],
    // PHP
    ['php', 'text/x-php'], ['phtml', 'application/x-httpd-php'],
    // Python
    ['py', 'text/x-python'],
    // Shell
    ['sh', 'text/x-sh'],
    // SCSS
    ['scss', 'text/x-scss'],
    // Video Text Tracks.
    ['vtt', 'text/vtt'],
    // LiveScript
    ['ls', 'text/x-livescript'],
    // Markdown
    ['md', 'text/markdown'],
    // ClojureScript
    ['cljs', 'text/x-clojure'], ['cljc', 'text/x-clojure'], ['cljx', 'text/x-clojure'],
    // Stylus
    ['styl', 'text/x-styl'],
    // JSX
    ['jsx', 'text/jsx'],
    // Image
    ['jpeg', 'image/jpeg'], ['jpg', 'image/jpeg'], ['svg', 'image/svg+xml'], ['gif', 'image/gif'], ['webp', 'image/webp'],
    ['png', 'image/png'], ['ico', 'image/ico'], ['tiff', 'image/tiff'], ['tif', 'image/tif'], ['bmp', 'image/bmp'],
    // Font
    ['ttf', 'font/opentype'], ['otf', 'font/opentype'], ['ttc', 'font/opentype'], ['woff', 'application/font-woff'],
]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
