"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var logger_1 = require("../../../utils/logger");
var objectManager_1 = require("../../../utils/objectManager");
var exceptionId = 0;
exports.default = (function (msg, socket, handlerContext) {
    socket.fireEvent({
        method: "Runtime.executionContextCreated" /* executionContextCreated */,
        params: {
            context: {
                // TODO: 随机生成的contextId，改用uuid来生成
                id: objectManager_1.executionContextId,
                origin: 'http://',
                name: 'http',
                uniqueId: "" + objectManager_1.executionContextId,
                auxData: {
                    frameId: objectManager_1.executionContextId,
                    isDefault: !0,
                },
            },
        },
    });
    window.onerror = function (ev, source, line, col, error) {
        if ((error === null || error === void 0 ? void 0 : error.name) === 'NetworkError') {
            socket.fireEvent({
                method: "Runtime.consoleAPICalled" /* consoleAPICalled */,
                params: {
                    type: 'error',
                    args: handlerContext.objectMapper.mapFrom([error], {
                        consoleMethod: 'error',
                    }),
                    executionContextId: objectManager_1.executionContextId,
                    timestamp: Date.now(),
                    stackTrace: {
                        // @ts-ignore
                        callFrames: handlerContext.callFramesMapper.mapFrom(error.stack),
                    },
                },
            });
            return;
        }
        exceptionId += 1;
        socket.fireEvent({
            method: "Runtime.exceptionThrown" /* exceptionThrown */,
            params: {
                timestamp: Date.now(),
                exceptionDetails: {
                    exception: handlerContext.objectMapper.mapFromOne(error),
                    exceptionId: exceptionId,
                    text: 'Uncaught',
                    lineNumber: line || 0,
                    columnNumber: col || 0,
                    executionContextId: objectManager_1.executionContextId,
                    stackTrace: {
                        // @ts-ignore
                        callFrames: handlerContext.callFramesMapper.mapFrom((error === null || error === void 0 ? void 0 : error.stack) || '', true),
                    },
                },
            },
        });
    };
    window.addEventListener('unhandledrejection', function (event) {
        logger_1.debug(event);
        exceptionId += 1;
        socket.fireEvent({
            method: "Runtime.exceptionThrown" /* exceptionThrown */,
            params: {
                timestamp: Date.now(),
                exceptionDetails: {
                    exception: handlerContext.objectMapper.mapFromOne(event.reason),
                    exceptionId: exceptionId,
                    text: 'Uncaught (in promise)',
                    lineNumber: 0,
                    columnNumber: 0,
                    executionContextId: objectManager_1.executionContextId,
                },
            },
        });
    });
});
