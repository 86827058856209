"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * 排除空的text元素
 * @param elem
 * @returns
 */
exports.default = (function (elem) {
    // empty or line-break text
    if (elem.nodeType == elem.TEXT_NODE) {
        if (elem.textContent.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$|\n+/g, '') == '') { // trim
            return true;
        }
    }
    return false;
});
