<script>
  export let show = false;
  export let margin = {};
  export let rect = {
    left: 0,
    top: 0,
  };
  export let border = {};
  export let padding = {};
  export let content = {};
</script>

<div
  style="
    border-color: {margin.color};
    border-bottom-width: {margin.bottom};
    border-left-width: {margin.left};
    border-right-width: {margin.right};
    border-top-width: {margin.top};
    border-style: solid;
    box-sizing: border-box;
    left: {rect.left};
    position: fixed;
    top: {rect.top};
    z-index: 99998;
    display: {show ? 'block' : 'none'};
    pointer-events: none;
  "
>
  <div
    style="
      border-color: {border.color};
      border-bottom-width: {border.bottom};
      border-left-width: {border.left};
      border-right-width: {border.right};
      border-top-width: {border.top};
      border-style: solid;
      box-sizing: border-box;
      height: {rect.height};
      width: {rect.width};
    "
  >
    <div
      style="
        border-color: {padding.color};
        border-bottom-width: {padding.bottom};
        border-left-width: {padding.left};
        border-right-width: {padding.right};
        border-top-width: {padding.top};
        border-style: solid;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
      "
    >
      <div
        style="
          background-color: {content.color};
          box-sizing: border-box;
          height: 100%;
          width: 100%;
        "
      ></div>
    </div>
  </div>
</div>