"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRecusionOwnPropertyNames = exports.callFn = exports.getStacktrace = exports.getFakeError = exports.getError = exports.getObjectProperties = exports.getConsoleArg = void 0;
var generateHash_1 = require("../generateHash");
var objectStore_1 = require("../objectStore");
var propertyObject_1 = require("../propertyObject");
/**
 * parse console properties properly
 * @param  {*}             arg  any kind of primitive or object
 * @return {RemoteObject}       Mirror object referencing original JavaScript object.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getConsoleArg(arg, returnByValue) {
    var property = new propertyObject_1.PropertyObject(arg);
    if (property['type'] === 'undefined') {
        return { type: property['type'] };
    }
    /**
       * return primitives right away
       */
    if (property['isPrimitive'] || (property['subtype'] === 'array' && returnByValue)) {
        return { type: property['type'], value: arg };
    }
    var result = property['get']();
    if (property['subtype'] !== 'node') {
        /**
             * apply preview for raw objects only
             */
        result.preview = {
            description: property['description'],
            overflow: false,
            properties: getObjectProperties(property['object']),
            type: property['type'],
            subtype: property['subtype'],
        };
    }
    return result;
}
exports.getConsoleArg = getConsoleArg;
function getObjectProperties(obj, includeDescriptors) {
    if (includeDescriptors === void 0) { includeDescriptors = false; }
    return Object.getOwnPropertyNames(obj).map(function (propertyName) {
        /**
             * ignore accessor and hide internal properties (_nodeId)
             */
        if (propertyName === 'length' || propertyName === 'constructor' || propertyName === '_nodeId') {
            return;
        }
        var descriptor = Object.getOwnPropertyDescriptor(obj, propertyName);
        var property = new propertyObject_1.PropertyObject(descriptor.value);
        /**
             * only return a subset of properties
             */
        if (!includeDescriptors) {
            var result = property['get']();
            result.name = propertyName;
            result.value = result.description;
            delete result.description;
            delete result.objectId;
            delete result.className;
            return result;
        }
        return {
            configurable: descriptor.configurable,
            enumerable: descriptor.enumerable,
            writable: descriptor.writable,
            name: propertyName,
            value: property['get'](),
            // eslint-disable-next-line no-prototype-builtins
            isOwn: obj.hasOwnProperty(propertyName),
        };
    }).filter(function (prop) { return Boolean(prop); });
}
exports.getObjectProperties = getObjectProperties;
/**
 * generates an error object
 * @param  {String} [message='fake']  error message (optional)
 * @return {Object}                   error object
 */
function getError(message, fakeStack) {
    if (message === void 0) { message = 'fake'; }
    if (fakeStack === void 0) { fakeStack = false; }
    try {
        throw new Error(message);
    }
    catch (err) {
        /**
             * fake stack if none existing
             * TV browser doesn't allow to modify error object (readonly) so we need to
             * fake the error object
             */
        if (!err.stack || fakeStack) {
            return getFakeError(err);
        }
        return err;
    }
}
exports.getError = getError;
/**
 * generates a fake error object since we can't modify the stack and eval errors come without
 */
function getFakeError(err) {
    var newError = {
        message: err.message,
        stack: err.constructor.name + ": " + err.message + "\n\tat <anonymous>:1:1",
    };
    newError.constructor = err.constructor;
    return newError;
}
exports.getFakeError = getFakeError;
/**
 * returns stacktrace data for console.log event
 */
function getStacktrace(err) {
    var error = err || getError();
    if (!error) {
        return [];
    }
    var splittedStack = error.stack.split('\n');
    return splittedStack.filter(function (line) {
        /**
             * filter out own functions
             */
        return !line.match(/^__(getStacktrace|fakeConsole)/);
    }).map(function (line) {
        var stackData = line.trim().match(/^(.*@)*(.*):(\d+):(\d+)$/);
        if (!stackData) {
            return null;
        }
        /**
             * ToDo assign _nodeId to each element on the page to get this working
             */
        var url = stackData[2];
        // eslint-disable-next-line @typescript-eslint/no-shadow
        var script = Array.from(document.querySelectorAll('script')).filter(function (script) {
            return script.src === url;
        })[0];
        var scriptVNodeId = script && script['__vnode__'] && script['__vnode__']['nodeId'];
        return {
            columnNumber: stackData[4],
            lineNumber: stackData[3],
            scriptId: script ? scriptVNodeId : 0,
            url: stackData[2],
            functionName: stackData[1] ? stackData[1].slice(0, 1) : '',
        };
    }).filter(function (stackData) { return Boolean(stackData); });
}
exports.getStacktrace = getStacktrace;
/**
 * executes a given expressions safely and returns its value or error
 * @param  {String} expression  javascript you want to execute
 * @return {Object}             result containing the expression value or error and objectId from store
 */
function callFn(expression, context) {
    var result = {
        value: null,
        error: null,
        scriptId: -1,
        hash: generateHash_1.default(expression),
    };
    try {
        result.value = (function (_code) { return eval(_code); }).apply(context, [expression]); // eslint-disable-line no-eval
    }
    catch (e) {
        result.error = e;
        // @ts-ignore
        result.error.wasThrown = true;
        /**
             * trigger scriptFailedToParse event when script can't be parsed
             */
        // scriptFailedToParse.call(this, script)
    }
    finally {
        // @ts-ignore
        var objectId = objectStore_1.default.save(result.value || result.error);
        var scriptId = JSON.parse(objectId).id;
        result.scriptId = scriptId;
    }
    return result;
}
exports.callFn = callFn;
/**
 * 向对象原型链递归读取对象的ownProperty
 */
function getRecusionOwnPropertyNames(obj) {
    if (!obj)
        return [];
    var result = __spreadArray([], Object.getOwnPropertyNames(obj));
    var prototype = Object.getPrototypeOf(obj);
    while (prototype) {
        var ownProperties = Object.getOwnPropertyNames(prototype);
        if (ownProperties.length) {
            result.push.apply(result, ownProperties);
        }
        prototype = Object.getPrototypeOf(prototype);
    }
    return result;
}
exports.getRecusionOwnPropertyNames = getRecusionOwnPropertyNames;
