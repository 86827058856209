"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg) {
    var params = msg.params;
    var nodeId = params.nodeId, value = params.value;
    var node = elementStore_1.default.get(nodeId);
    if (node.element) {
        node.element.nodeValue = value;
    }
});
