"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getResponseType(mimeType) {
    if (mimeType.startsWith('text/html')) {
        return 'document';
    }
    if (mimeType.startsWith('application/json')) {
        return 'json';
    }
    if (mimeType.startsWith('text/')) {
        return 'text';
    }
    if (mimeType.startsWith('image/')) {
        return 'arraybuffer';
    }
    return '';
}
exports.default = getResponseType;
