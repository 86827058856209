"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var enable_1 = require("./enable");
var getComputedStyleForNode_1 = require("./getComputedStyleForNode");
var getInlineStylesForNode_1 = require("./getInlineStylesForNode");
var getMatchedStylesForNode_1 = require("./getMatchedStylesForNode");
var getStyleSheetText_1 = require("./getStyleSheetText");
var setStyleTexts_1 = require("./setStyleTexts");
exports.default = {
    enable: enable_1.default,
    getMatchedStylesForNode: getMatchedStylesForNode_1.default,
    getComputedStyleForNode: getComputedStyleForNode_1.default,
    getInlineStylesForNode: getInlineStylesForNode_1.default,
    setStyleTexts: setStyleTexts_1.default,
    getStyleSheetText: getStyleSheetText_1.default,
};
