"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = void 0;
var config_1 = require("./common/config");
var core_1 = require("./lib/core");
var aes_1 = require("./utils/aes");
var docReady_1 = require("./utils/docReady");
var logger_1 = require("./utils/logger");
var framework_monitor_1 = require("./utils/monitor/framework-monitor");
var monitor = framework_monitor_1.default(config_1.default.yuyanId);
function init(opts) {
    if (!opts) {
        logger_1.error('Cannot init without opts');
        return;
    }
    var uuid = opts.uuid, observerElement = opts.observerElement;
    if (!uuid) {
        logger_1.error('Cannot init without uuid');
        return;
    }
    monitor.setParams({
        tyroId: opts.uuid,
    });
    monitor.logPv();
    monitor.logSplashScreen();
    docReady_1.default(function () {
        window['__debugSdk__'] = new core_1.default({
            uuid: uuid,
            observerElement: observerElement,
        });
        try {
            var aplus = document.createElement('script');
            aplus.src = 'https://g.alicdn.com/alilog/mlog/aplus_v2.js';
            aplus.onload = function () {
                aes_1.pv.sendPV();
            };
            document.body.appendChild(aplus);
        }
        catch (err) {
            logger_1.error('aplus init failed');
        }
    });
}
exports.init = init;
