"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pv = exports.sendEvent = void 0;
var aes_tracker_1 = require("@ali/aes-tracker");
var aes_tracker_plugin_event_1 = require("@ali/aes-tracker-plugin-event");
var aes_tracker_plugin_pv_1 = require("@ali/aes-tracker-plugin-pv");
var aes = new aes_tracker_1.default({
    pid: 'yfp3cj',
    user_type: '20',
});
// @ts-ignore
window.aes = aes;
exports.sendEvent = aes.use(aes_tracker_plugin_event_1.default);
exports.pv = aes.use(aes_tracker_plugin_pv_1.default, { autoPV: false });
exports.default = aes;
