"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClientWsUrl = exports.getAgentWsUrl = exports.getAgentId = void 0;
function getAgentId() {
    return 1;
}
exports.getAgentId = getAgentId;
function getAgentWsUrl(tyroId) {
    return "wss://hpmweb.alipay.com/bugme/agent/" + tyroId + "?agentId=" + getAgentId();
}
exports.getAgentWsUrl = getAgentWsUrl;
function getClientWsUrl(tyroId) {
    return "wss://hpmweb.alipay.com/bugme/client_w/" + tyroId;
}
exports.getClientWsUrl = getClientWsUrl;
