"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var superagent = require("superagent");
var monitorUtils = require("./monitor-utils");
var util = require("./util");
var serverUrl = 'https://mdap.alipay.com/loggw/dwcookieLogGet.do';
var version = '0.1.10';
function getGlobal() {
    if (!eval) {
        return global || window;
    }
    return ((0, eval)('this'));
}
var Monitor = /** @class */ (function () {
    function Monitor(_opts) {
        var _this = this;
        if (_opts === void 0) { _opts = {}; }
        this.eventId = '102022'; // PC 端监控事件
        this.logCache = [];
        this.userConfig = {};
        this._warn = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var win = getGlobal();
            if (typeof win === 'object' &&
                win.console &&
                typeof win.console.warn === 'function' &&
                _this.debug) {
                var params = Array.prototype.slice.call(args);
                win.console.warn.apply(null, ["[Monitor Debug]" + params[0], params.slice(1)]);
            }
        };
        var opts = monitorUtils.getOptionsDefaulter().process(_opts);
        this.userConfig = opts;
        this.appid = opts.bmAppid;
        this.debug = opts.debug;
        this.beforeLog = opts.beforeLog;
        this.defaults = opts.defaults || {};
        this.requiredFields = opts.requiredFields;
    }
    Monitor.prototype.log = function (logItem) {
        return __awaiter(this, void 0, void 0, function () {
            var itemList;
            var _this = this;
            return __generator(this, function (_a) {
                itemList = Array.isArray(logItem) ? logItem : [logItem];
                Promise.all(itemList.map(function (item) { return _this._log(item); }));
                return [2 /*return*/];
            });
        });
    };
    /**
       * 将上报值添加上必要的参数，并转化为日志格式
       * @param item
       */
    Monitor.prototype.parseItem = function (item) {
        if (!item || typeof item !== 'object') {
            return [];
        }
        // 处理日期
        var dt = new Date();
        // 时间格式补零
        var fillDate = function (num) { return (num < 10 ? "0" + num : "" + num); };
        // 处理用户ID
        var userId = item.userId || item.roleId || this.userConfig.userId || '';
        delete item.roleId;
        delete item.userId;
        var param4 = Object.assign(Object.assign({}, this.defaults), item);
        if (this.appid) {
            param4.bm_appid = this.appid;
        }
        if (!monitorUtils.allFieldsReady(this.userConfig, this.requiredFields, this._warn.bind(this))) {
            this.logCache.push(item);
            return null;
        }
        if (this.userConfig.sprintId) {
            param4.bm_sid = this.userConfig.sprintId;
        }
        if (this.userConfig.env) {
            param4.env = this.userConfig.env;
        }
        if (this.userConfig.yuyanId) {
            param4.yuyan_id = this.userConfig.yuyanId;
        }
        param4.tracert_ver = version;
        if (this.beforeLog && typeof this.beforeLog === 'function') {
            param4 = this.beforeLog(param4);
            if (!param4 || typeof param4 !== 'object') {
                this._warn('beforeLog返回值不是对象，不进行上报');
                return null;
            }
        }
        var data = [
            'D-AE',
            dt.getFullYear() + "-" + fillDate(dt.getMonth() + 1) + "-" + fillDate(dt.getDate()) + " " + fillDate(dt.getHours()) + ":" + fillDate(dt.getMinutes()) + ":" + fillDate(dt.getSeconds()) + ":" + dt.getMilliseconds(),
            '',
            '',
            '2',
            '',
            '',
            userId,
            '1000',
            this.eventId,
            'H5behavior',
            '2',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''.concat("" + window.innerWidth || '', 'x').concat("" + window.innerHeight, 'x').concat("" + window.devicePixelRatio),
            '',
            '',
            '',
            navigator.userAgent.replace(/,/g, '%2C'),
            '',
            '',
            '',
            '',
            '',
            '',
        ];
        data.push(util.objToStr(param4, '^', true));
        return data;
    };
    Monitor.prototype._log = function (logItem) {
        return __awaiter(this, void 0, void 0, function () {
            var logData;
            return __generator(this, function (_a) {
                logData = this.parseItem(logItem);
                if (!logData || !logData.length) {
                    return [2 /*return*/];
                }
                return [2 /*return*/, this.sendRequest(logData)];
            });
        });
    };
    Monitor.prototype.sendRequest = function (data) {
        return superagent
            .post(serverUrl)
            .type('form')
            .send({
            data: data.join(),
            time: new Date().getTime(),
        })
            .set({
            'Sec-Fetch-Mode': 'cors',
        });
    };
    return Monitor;
}());
exports.default = Monitor;
