"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg, socket) {
    var id = msg.id, params = msg.params;
    var nodeId = params.nodeId;
    var element = elementStore_1.default.get(nodeId);
    var computedStyle = window.getComputedStyle((element.element));
    var computedStyleArray = [];
    for (var key in computedStyle) {
        if (!isNaN(Number(key))) {
            computedStyleArray.push({
                name: computedStyle[key],
                value: computedStyle.getPropertyValue(computedStyle[key]),
            });
        }
    }
    socket.response({
        id: id,
        result: {
            computedStyle: computedStyleArray,
        },
    });
});
