"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var evalFunc = typeof eval === 'function' ? eval : function () { };
exports.default = (function (msg, socket, handlerContext) {
    var params = msg.params;
    var expression = params.expression;
    try {
        var returnValue = evalFunc(expression);
        var result = handlerContext.objectMapper.mapFromOne(returnValue);
        socket.response({
            id: msg.id,
            result: {
                result: result,
            },
        });
    }
    catch (error) {
        // 去除 ErrorStack: 1. 防止内部实现透出；2. 压缩产物 Stack 不实用
        error.stack = error.name + ": " + error.message;
        socket.response({
            id: msg.id,
            result: {
                exceptionDetails: {
                    columnNumber: 20,
                    exception: handlerContext.objectMapper.mapFromOne(error),
                    exceptionId: 1,
                    lineNumber: 0,
                    text: 'Uncaught',
                },
                result: handlerContext.objectMapper.mapFromOne(error),
            },
        });
    }
    // if (generatePreview || objectGroup === 'completion') {
    //   const { error, scriptId, hash } = callFn(expression);
    //   // @ts-ignore
    //   if (error && error.wasThrown) {
    //     const exceptionDetails = {
    //       columnNumber: 0,
    //       exception: getConsoleArg(error, scriptId),
    //       exceptionId: scriptId,
    //       lineNumber: 0,
    //       scriptId: scriptId.toString(),
    //       text: 'Uncaught',
    //     };
    //     return { exceptionDetails };
    //   }
    //   socket.fireEvent<DebuggerEvent.scriptParsed>({
    //     method: DebuggerEvent.scriptParsed,
    //     params: {
    //       hash,
    //       startColumn: 0,
    //       endColumn: 0,
    //       startLine: 0,
    //       endLine: 0,
    //       executionContextId,
    //       executionContextAuxData: {
    //         frameId: '1.0',
    //         isDefault: true,
    //       },
    //       scriptId: `${scriptId}`,
    //       hasSourceURL: false,
    //       isLiveEdit: false,
    //       sourceMapURL: '',
    //       url: '',
    //     },
    //   });
    // }
    // const result = objectStore.getLastObject();
    // const scriptId = objectStore.getLastScriptId();
    // if (result instanceof Error && result['wasThrown']) {
    //   const newError = getFakeError(result);
    //   const errorResult = getConsoleArg(newError, scriptId, returnByValue);
    //   return socket.response<Runtime.evaluate>({
    //     id,
    //     result: {
    //       result: errorResult,
    //       exceptionDetails: {
    //         columnNumber: 0,
    //         lineNumber: 0,
    //         scriptId: scriptId.toString(),
    //         exception: errorResult,
    //         exceptionId: scriptId,
    //         stackTrace: { callFrames: getStacktrace(newError) },
    //         text: newError.constructor.name,
    //       },
    //     },
    //   });
    // }
    // if (objectGroup === 'completion' && !returnByValue) {
    //   const constructorName = result && result.constructor ? result.constructor.name : undefined;
    //   return socket.response<Runtime.evaluate>({
    //     id,
    //     result: {
    //       result: {
    //         className: constructorName,
    //         description: constructorName,
    //         objectId: JSON.stringify({ injectedScriptId: 1, id: scriptId }),
    //         type: typeof result,
    //       },
    //     },
    //   });
    // }
    // /**
    //    * in case evaluate throws an error or returns one we need to fake the stack
    //    * in order to not send debugger stacktraces
    //    */
    // if (result instanceof Error) {
    //   return socket.response<Runtime.evaluate>({
    //     id,
    //     result: { result: getConsoleArg(getFakeError(result), scriptId, returnByValue) },
    //   });
    // }
    // return socket.response<Runtime.evaluate>({
    //   id,
    //   result: { result: getConsoleArg(result, scriptId, returnByValue) },
    // });
});
