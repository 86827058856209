"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg) {
    var params = msg.params;
    if (params && params.nodeId) {
        var node = elementStore_1.default.get(params.nodeId);
        node.element.outerHTML = params.outerHTML;
    }
});
