"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var logger_1 = require("../../../utils/logger");
var elementStore_1 = require("../../elementStore");
exports.default = (function (msg, _, handlerContext) {
    var params = msg.params;
    var nodeId = params.nodeId;
    var highlightConfig = params.highlightConfig;
    if (lodash_1.isUndefined(nodeId))
        return;
    var element = elementStore_1.default.get(nodeId);
    if (element) {
        handlerContext.highlightNode(element.element, highlightConfig);
    }
    else {
        logger_1.error('Cannot find element by nodeId ', nodeId);
    }
});
