"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (element, attr) {
    if (!element) {
        return;
    }
    var attributeMap = element.attributes;
    var val = attributeMap.getNamedItem(attr);
    return (val === null || val === void 0 ? void 0 : val.value) || '';
});
