"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getReasonByEventCode_1 = require("../utils/getReasonByEventCode");
var getWebsocketUrl_1 = require("../utils/getWebsocketUrl");
var logger_1 = require("../utils/logger");
var websocket_1 = require("../utils/websocket");
var ChromeDevToolsProtocolFactory = /** @class */ (function () {
    function ChromeDevToolsProtocolFactory(opts) {
        this.opts = opts;
        this.initial();
    }
    ChromeDevToolsProtocolFactory.prototype.response = function (msgWillSend) {
        logger_1.debug('----------> response', msgWillSend);
        this.agentSocket.send(JSON.stringify(msgWillSend));
    };
    ChromeDevToolsProtocolFactory.prototype.fireEvent = function (msg) {
        logger_1.debug('----------> fire event', msg);
        if ([
            "MiniAppLog.enable" /* enable */,
            "MiniAppLog.onApiCall" /* onApiCall */,
            "MiniAppLog.onApiCallback" /* onApiCallback */,
        ].indexOf(msg.method) !== -1) {
            this.clientSocket.send(JSON.stringify(msg));
        }
        else {
            this.agentSocket.send(JSON.stringify(msg));
        }
    };
    ChromeDevToolsProtocolFactory.prototype.initial = function () {
        var _this = this;
        /** 初始化websocket链接 */
        var agentSocketUrl = getWebsocketUrl_1.getAgentWsUrl(this.opts.uuid); // 用来处理html/css/storage/network
        var clientSocketUrl = getWebsocketUrl_1.getClientWsUrl(this.opts.uuid); // 用来处理console/source
        this.agentSocket = new websocket_1.default({
            url: agentSocketUrl,
            onOpen: function (ev) {
                var onOpen = _this.opts.onOpen;
                onOpen && onOpen.call(_this.agentSocket, ev);
                logger_1.info(agentSocketUrl + " has connected");
            },
            onMessage: this.opts.onMessage,
            onError: function (ev) {
                logger_1.error(agentSocketUrl + " occured error", ev);
            },
            onClose: function (ev) {
                var reason = getReasonByEventCode_1.default(ev);
                logger_1.error(agentSocketUrl + " closed. Code: " + ev.code + ". Message: " + reason);
                var onClose = _this.opts.onClose;
                onClose && onClose.call(_this.agentSocket, ev);
            },
        });
        this.clientSocket = new websocket_1.default({
            url: clientSocketUrl,
            onMessage: this.opts.onMessage,
            onError: function () {
                logger_1.error(clientSocketUrl + " occured error");
            },
            onClose: function (ev) {
                var reason = getReasonByEventCode_1.default(ev);
                logger_1.error(clientSocketUrl + " closed. Code: " + ev.code + ". Message: " + reason);
                var onClose = _this.opts.onClose;
                onClose && onClose.call(_this.clientSocket, ev);
            },
        });
    };
    return ChromeDevToolsProtocolFactory;
}());
exports.default = ChromeDevToolsProtocolFactory;
