"use strict";
/**
 * 根据DOM元素映射元素对应的接口
 * 比如 <p></p> => HTMLParagraphElement
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTML_UNKNOWN_ELEMENT = void 0;
var logger_1 = require("./logger");
exports.HTML_UNKNOWN_ELEMENT = 'HTMLUnknownElement';
exports.default = (function (elem) {
    var localName = elem.localName;
    if (!localName)
        return exports.HTML_UNKNOWN_ELEMENT;
    var fakeElement = document.createElement(localName);
    try {
        var ctor = fakeElement['constructor'].toString();
        var matches = ctor.match(/function (.+)\(\)/);
        return matches ? matches[1] : exports.HTML_UNKNOWN_ELEMENT;
    }
    catch (e) {
        logger_1.error(e);
        return exports.HTML_UNKNOWN_ELEMENT;
    }
});
