"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var callFunctionOn_1 = require("./callFunctionOn");
var compileScript_1 = require("./compileScript");
var enable_1 = require("./enable");
var evaluate_1 = require("./evaluate");
var getProperties_1 = require("./getProperties");
var globalLexicalScopeNames_1 = require("./globalLexicalScopeNames");
var releaseObjectGroup_1 = require("./releaseObjectGroup");
exports.default = {
    enable: enable_1.default,
    compileScript: compileScript_1.default,
    evaluate: evaluate_1.default,
    getProperties: getProperties_1.default,
    callFunctionOn: callFunctionOn_1.default,
    releaseObjectGroup: releaseObjectGroup_1.default,
    globalLexicalScopeNames: globalLexicalScopeNames_1.default,
};
