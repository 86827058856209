"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var svelte_toast_1 = require("svelte-toast");
var modal_svelte_1 = require("../../svelte/modal.svelte");
var overlay_svelte_1 = require("../../svelte/overlay.svelte");
var callframes_mapper_1 = require("../../utils/callframes-mapper");
var getDOMBoxModelSize_1 = require("../../utils/getDOMBoxModelSize");
var getPropertiesHelper_1 = require("../../utils/getPropertiesHelper");
var objectManager_1 = require("../../utils/objectManager");
var objectStore_1 = require("../../utils/objectStore");
var elementStore_1 = require("../elementStore");
var Context = /** @class */ (function () {
    function Context(opts) {
        this.observerElement = opts.observerElement;
        this.elementStore = elementStore_1.default;
        this.overlayComponent = new overlay_svelte_1.default({
            props: {
                show: false,
            },
            target: document.body,
        });
        this.modalComponent = new modal_svelte_1.default({
            props: {
                show: false,
            },
            target: document.body,
        });
        this.tipsComponent = new svelte_toast_1.default({
            position: 'top-center',
            duration: 2000,
        });
        this.callFramesMapper = new callframes_mapper_1.CallFramesMapper();
        this.objectStore = objectStore_1.default;
        this.objectMapper = new objectManager_1.ObjectMapper(this.objectStore);
        this.getPropertiesHandler = new getPropertiesHelper_1.GetPropertiesHandler(this.objectStore, this.objectMapper);
    }
    Context.prototype.highlightNode = function (node, highlightConfig) {
        if (!highlightConfig) {
            highlightConfig = {};
        }
        if (!node || !node.getBoundingClientRect) {
            this.hidehighlight();
            return;
        }
        var rect = node.getBoundingClientRect();
        // 0 尺寸不展示高亮遮罩
        if (!rect.height && !rect.width) {
            this.hidehighlight();
            return;
        }
        var boxModelSize = getDOMBoxModelSize_1.default(node);
        var getBackgroundColor = function (argb) {
            return argb ? "rgba(" + argb.r + ", " + argb.g + ", " + argb.b + ", " + argb.a + ")" : 'transparent';
        };
        var config = {
            border: lodash_1.assign(lodash_1.assign({}, boxModelSize.border), { color: getBackgroundColor(highlightConfig.borderColor) }),
            content: {
                color: getBackgroundColor(highlightConfig.contentColor),
            },
            margin: lodash_1.assign(lodash_1.assign({}, boxModelSize.margin), { color: getBackgroundColor(highlightConfig.marginColor) }),
            padding: lodash_1.assign(lodash_1.assign({}, boxModelSize.padding), { color: getBackgroundColor(highlightConfig.paddingColor) }),
            rect: {
                height: rect.height + "px",
                left: rect.left - Number(boxModelSize.margin.left.slice(0, -2)) + "px",
                top: rect.top - Number(boxModelSize.margin.top.slice(0, -2)) + "px",
                width: rect.width + "px",
            },
            show: true,
        };
        this.overlayComponent.$$set(config);
    };
    Context.prototype.hidehighlight = function () {
        this.overlayComponent.$$set({
            rect: {
                height: '0px',
                left: '0px',
                top: '0px',
                width: '0px',
            },
            show: false,
        });
    };
    return Context;
}());
exports.default = Context;
