"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (str) {
    var hash = 0;
    var i;
    var chr;
    if (str.length === 0)
        return String(hash);
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return String(hash);
});
