"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var css_1 = require("../../../utils/css");
exports.default = (function (msg, socket) {
    var id = msg.id, params = msg.params;
    var nodeId = params.nodeId;
    socket.response({
        id: id,
        result: {
            inlineStyle: css_1.getInlineStylesForNode(nodeId),
        },
    });
});
